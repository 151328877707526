import React from "react";

function Footer() {
  return (
    <footer className="text-white lg:px-24 md:px-9 bg-black">
        {/* Footer Bottom */}
        <div className="text-sm">
          <div className="flex flex-wrap items-center justify-center lg:gap-2 sm:gap-2 md:gap-2 text-center">
            <p className="text-gray-400">
              &copy; 2024 Bharat AstroCard. All rights reserved.
            </p>
            <span className="Poppins flex items-center text-gray-400">
              {/* <i className="fas fa-map-marker-alt mr-1"></i> */}
              #8-3-231/F/33, Sri Krishna Nagar, Yousufguda, Hyderabad - 500045
            </span>
            <button
              onClick={() => window.open("mailto:info@techpixe.com")}
              className="Poppins flex items-center text-gray-400 hover:text-gray-200"
            >
              <i className="fas fa-envelope mr-1"></i> bharatastrosage@gmail.com
            </button>

            <div className="flex items-center space-x-1">
              <button
                onClick={() => window.open("https://facebook.com", "_blank")}
                className="group transition bg-transparent p-2 rounded-full"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f text-lg text-gray-400 group-hover:text-blue-600"></i>
              </button>

              <button
                onClick={() => window.open("https://twitter.com", "_blank")}
                className="group transition bg-transparent p-2 rounded-full"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter text-lg text-gray-400 group-hover:text-sky-500"></i>
              </button>

              <button
                onClick={() => window.open("https://instagram.com", "_blank")}
                className="group transition bg-transparent p-2 rounded-full"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram text-lg text-gray-400 group-hover:text-pink-400"></i>
              </button>

              <button
                onClick={() => window.open("https://youtube.com", "_blank")}
                className="group transition bg-transparent p-2 rounded-full"
                aria-label="YouTube"
              >
                <i className="fab fa-youtube text-lg text-gray-400 group-hover:text-red-500"></i>
              </button>
            </div>
          </div>
        </div>
    </footer>
  );
}

export default Footer;
