import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import logo from "../../assets/images/astroLogo.png";
// import axios from "axios";
// import { BASE_URL } from "../../apiContstants";

function InvoiceBill({ invoiceCard }) {
  // const [invoiceData, setInvoiceData] = useState(null);
  // console.log(invoiceData)

  const loginUser = JSON.parse(localStorage.getItem("loginUser")) || [];
  const userProfileId =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].userProfileId
      : loginUser?.userProfileId || "";

  // const fetchInvoiceData = async () => {
  //   if (!userProfileId) return;
  //   const response = await axios.get(
  //     `${BASE_URL}api/shiprocket/getInvoice/${userProfileId}`
  //   );
  //   console.log(response.data);
  //   setInvoiceData(response.data);
  // };

  // useEffect(() => {
  //   fetchInvoiceData();
  // }, []);

  const styles = {
    page: {
      padding: 30,
      paddingTop: 30,
      fontFamily: "Helvetica",
    },
    header: {
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center",
      color: "#4F46E5",
    },
    section: {
      paddingTop: 10,
      marginBottom: 15,
    },
    table: {
      width: "100%",
      borderWidth: 1,
      borderColor: "#ddd",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableRow: {
      flexDirection: "row",
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      justifyContent: "space-between",
    },
    tableCellHeader: {
      flex: 1,
      paddingHorizontal: 8,
      textAlign: "center",
      fontWeight: "semi-bold",
      backgroundColor: "#f0f0f0",
      padding: 4,
    },
    tableCell: {
      flex: 1,
      paddingHorizontal: 8,
      textAlign: "center",
      fontSize: 12,
    },
    text: {
      fontSize: 11,
      marginBottom: 5,
    },
    orderText: {
      fontSize: 12,
      marginBottom: 20,
    },
    subHeader: {
      fontSize: 15,
      marginBottom: 5,
    },
    addressHeader: {
      marginBottom: 8,
      marginTop: 15,
    },
    totalSection: {
      marginTop: 20,
      textAlign: "right",
      fontSize: 14,
      fontWeight: "bold",
    },
    horizontalLine: {
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      marginVertical: 10,
    },
    logo: {
      position: "absolute",
      top: 0,
      left: 20,
      width: 100,
      height: 100,
    },
  };

  const planType = JSON.parse(localStorage.getItem("planType"));
  const planTypeCard = planType.includes("Card");
  // const planTypeCardAndWeb = planType.includes("Card and Web")
  // const planTypeWeb = planType.includes("Web")

  const cardInvoice = invoiceCard?.filter(
    (item) => item.planType === "Card"
  );
  // console.log(cardInvoice);
  

  const finalAmountCard = cardInvoice?.reduce((total, card) => {
    const planAmount = card?.planAmount || 0;
    const frightCharges = card?.frightCharges || 0;
    return total + planAmount + Math.round(frightCharges);
  }, 0);

  //   const finalAmountCardAndWeb = cardAndWebData?.reduce((total, card) => {
  //     const planAmount = card?.planAmount || 0;
  //     const frightCharges = card?.frightCharges || 0;
  //     return total + planAmount + Math.round(frightCharges);
  // }, 0);

  return (
    <>
      {
        // (cardAndWebData && cardAndWebData.map((itemcard) => (
        //     itemcard.planType === "Card and Web" && planTypeCardAndWeb ?
        //     <Document key={itemcard.userProfileId}>
        //       <Page size="A4" style={styles.page}>
        //         <Image style={styles.logo} src={logo} />

        //         <Text style={styles.header}>
        //           Invoice for Order {itemcard.uuId || "N/A"}
        //         </Text>

        //         <View style={styles.section}>
        //           <Text style={styles.subHeader}>
        //             Invoice Number:{" "}
        //             <Text style={styles.text}>{itemcard.uuId || "N/A"}</Text>
        //           </Text>
        //           <Text style={styles.subHeader}>
        //             Order Date:{" "}
        //             <Text style={styles.text}>{itemcard.orderDate || "N/A"}</Text>
        //           </Text>
        //           <Text style={styles.subHeader}>
        //             Customer Name:{" "}
        //             <Text style={styles.text}>{`${
        //               itemcard.shipping_customer_name || ""
        //             } ${itemcard.shipping_last_name || ""}`}</Text>
        //           </Text>
        //           <Text style={styles.subHeader}>
        //             Order Total:{" "}
        //             <Text style={styles.text}>{finalAmountCardAndWeb || 0} /-</Text>
        //           </Text>
        //         </View>
        //         <View style={styles.section}>
        //           <View style={styles.horizontalLine}></View>
        //           <View style={styles.addressSection}>
        //             <Text style={styles.subHeader}>Items Ordered:</Text>
        //             <Text style={styles.orderText}>
        //               {" "}
        //               Bharat AstroCard NFC designed for mobile use with
        //               unlimited lifetime validity.
        //             </Text>
        //           </View>
        //           <View style={styles.addressSection}>
        //             <Text style={styles.addressHeader}>Delivery Address:</Text>
        //             <Text style={styles.text}>
        //               {" "}
        //               {`${itemcard.shipping_customer_name || ""} ${
        //                 itemcard.shipping_last_name || ""
        //               }`}
        //             </Text>
        //             <Text style={styles.text}>
        //               {" "}
        //               {itemcard.shipping_address || "N/A"},{" "}
        //               {itemcard.shipping_city || "N/A"}
        //             </Text>
        //             <Text style={styles.text}>
        //               {" "}
        //               {itemcard.shipping_state || "N/A"},{" "}
        //               {itemcard.shipping_pincode || "N/A"}
        //             </Text>
        //             <Text style={styles.text}>
        //               {" "}
        //               {itemcard.shipping_country || "N/A"}
        //             </Text>
        //             <Text style={styles.text}>
        //               {" "}
        //               email: {itemcard.shipping_email || "N/A"}
        //             </Text>
        //             <Text style={styles.text}>
        //               {" "}
        //               mobile: {itemcard.shipping_phone || "N/A"}
        //             </Text>
        //           </View>
        //         </View>
        //         <View>
        //           <Text style={styles.subHeader}>Delivery Option:</Text>
        //           <Text style={styles.text}>Standard Delivery</Text>
        //         </View>

        //         <View style={styles.table}>
        //           <View style={styles.tableRow}>
        //             <Text style={styles.tableCellHeader}>Item</Text>
        //             <Text style={styles.tableCellHeader}>Quantity</Text>
        //             <Text style={styles.tableCellHeader}>Price</Text>
        //             <Text style={styles.tableCellHeader}>Shipping charge</Text>
        //             <Text style={styles.tableCellHeader}>Total</Text>
        //           </View>
        //           {cardAndWebData.map((item, index) => (
        //             <View style={styles.tableRow}>
        //               <Text style={styles.tableCell}>Astro Card</Text>
        //               <Text style={styles.tableCell}>1</Text>
        //               <Text style={styles.tableCell}>
        //                 {item.planAmount || 0} /-
        //               </Text>
        //               <Text style={styles.tableCell}>
        //                 {item.frightCharges || 0} /-
        //               </Text>
        //               <Text style={styles.tableCell}>
        //                 {finalAmountCardAndWeb || 0} /-
        //               </Text>
        //             </View>
        //           ))}
        //         </View>
        //         <View style={styles.totalSection}>
        //           <Text>Grand Total: {finalAmountCardAndWeb || 0} /-</Text>
        //         </View>
        //       </Page>
        //     </Document>
        //     :
        //     null
        //   )))
        //   ||
        cardInvoice &&
          cardInvoice.map((itemcard) =>
            itemcard.planType === "Card" && planTypeCard ? (
              <Document key={itemcard.userProfileId}>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.logo} src={logo} />

                  <Text style={styles.header}>
                    Invoice for Order {itemcard.uuId || "N/A"}
                  </Text>

                  <View style={styles.section}>
                    <Text style={styles.subHeader}>
                      Invoice Number:{" "}
                      <Text style={styles.text}>{itemcard.uuId || "N/A"}</Text>
                    </Text>
                    <Text style={styles.subHeader}>
                      Order Date:{" "}
                      <Text style={styles.text}>
                        {itemcard.orderDate
                          ? new Date(itemcard.orderDate).toLocaleDateString("en-UK")
                          : "N/A"}
                      </Text>
                    </Text>
                    <Text style={styles.subHeader}>
                      Customer Name:{" "}
                      <Text style={styles.text}>{`${
                        itemcard.shipping_customer_name || ""
                      } ${itemcard.shipping_last_name || ""}`}</Text>
                    </Text>
                    <Text style={styles.subHeader}>
                      Order Total:{" "}
                      <Text style={styles.text}>{finalAmountCard || 0} /-</Text>
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <View style={styles.horizontalLine}></View>
                    <View style={styles.addressSection}>
                      <Text style={styles.subHeader}>Items Ordered:</Text>
                      <Text style={styles.orderText}>
                        {" "}
                        Bharat AstroCard NFC designed for mobile use with
                        unlimited lifetime validity.
                      </Text>
                    </View>
                    <View style={styles.addressSection}>
                      <Text style={styles.addressHeader}>
                        Delivery Address:
                      </Text>
                      <Text style={styles.text}>
                        {" "}
                        {`${itemcard.shipping_customer_name || ""} ${
                          itemcard.shipping_last_name || ""
                        }`}
                      </Text>
                      <Text style={styles.text}>
                        {" "}
                        {itemcard.shipping_address || "N/A"},{" "}
                        {itemcard.shipping_city || "N/A"}
                      </Text>
                      <Text style={styles.text}>
                        {" "}
                        {itemcard.shipping_state || "N/A"},{" "}
                        {itemcard.shipping_pincode || "N/A"}
                      </Text>
                      <Text style={styles.text}>
                        {" "}
                        {itemcard.shipping_country || "N/A"}
                      </Text>
                      <Text style={styles.text}>
                        {" "}
                        email: {itemcard.shipping_email || "N/A"}
                      </Text>
                      <Text style={styles.text}>
                        {" "}
                        mobile: {itemcard.shipping_phone || "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.subHeader}>Delivery Option:</Text>
                    <Text style={styles.text}>Standard Delivery</Text>
                  </View>

                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableCellHeader}>Item</Text>
                      <Text style={styles.tableCellHeader}>Quantity</Text>
                      <Text style={styles.tableCellHeader}>Price</Text>
                      <Text style={styles.tableCellHeader}>
                        Shipping charge
                      </Text>
                      <Text style={styles.tableCellHeader}>Total</Text>
                    </View>
                    {cardInvoice.map((item, index) => (
                      <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableCell}>Astro Card</Text>
                        <Text style={styles.tableCell}>1</Text>
                        <Text style={styles.tableCell}>
                          {item.planAmount || 0} /-
                        </Text>
                        <Text style={styles.tableCell}>
                          {Math.round(item.frightCharges) || 0} /-
                        </Text>
                        <Text style={styles.tableCell}>
                          {finalAmountCard || 0} /-
                        </Text>
                      </View>
                    ))}
                  </View>
                  <View style={styles.totalSection}>
                    <Text>Grand Total: {finalAmountCard || 0} /-</Text>
                  </View>
                </Page>
              </Document>
            ) : null
          )
        // ||
        // (invoiceWeb &&
        //   invoiceWeb.map((itemweb) => (
        //     itemweb.planType === "Web" && planTypeWeb ?
        //     <Document>
        //       <Page size="A4" style={styles.page}>
        //         <Image style={styles.logo} src={logo} />

        //         <Text style={styles.header}>
        //           Invoice for Order {itemweb.uuId || "N/A"}
        //         </Text>

        //         <View style={styles.section}>
        //           <Text style={styles.subHeader}>
        //             Invoice Number:{" "}
        //             <Text style={styles.text}>{itemweb.uuId || "N/A"}</Text>
        //           </Text>
        //           <Text style={styles.subHeader}>
        //             Order Date:{" "}
        //             <Text style={styles.text}>
        //               {itemweb.orderDate || "N/A"}
        //             </Text>
        //           </Text>
        //           <Text style={styles.subHeader}>
        //             Customer Name:{" "}
        //             <Text style={styles.text}>{`${itemweb.firstName || ""} ${
        //               itemweb.lastName || ""
        //             }`}</Text>
        //           </Text>
        //           <Text style={styles.subHeader}>
        //             Order Total:{" "}
        //             <Text style={styles.text}>
        //               {itemweb.paymentAmount || 0} /-
        //             </Text>
        //           </Text>
        //         </View>
        //         <View style={styles.section}>
        //           <View style={styles.horizontalLine}></View>
        //           <View style={styles.addressSection}>
        //             <Text style={styles.subHeader}>Items Ordered:</Text>
        //             <Text style={styles.orderText}>
        //               {" "}
        //               Bharat Astrosage designed for lifetime unlimited validity.
        //             </Text>
        //           </View>
        //         </View>
        //         <View style={styles.table}>
        //           <View style={styles.tableRow}>
        //             <Text style={styles.tableCellHeader}>Item</Text>
        //             <Text style={styles.tableCellHeader}>Plan</Text>
        //             <Text style={styles.tableCellHeader}>Price</Text>
        //           </View>
        //           <View style={styles.tableRow}>
        //             <Text style={styles.tableCell}>Astrosage</Text>
        //             <Text style={styles.tableCell}>{"Astrosage"} /-</Text>
        //             <Text style={styles.tableCell}>
        //               {itemweb.paymentAmount || 0} /-
        //             </Text>
        //           </View>
        //         </View>
        //         <View style={styles.totalSection}>
        //           <Text>Grand Total: {itemweb.paymentAmount} /-</Text>
        //         </View>
        //       </Page>
        //     </Document>
        //     : null
        //   )))
      }
    </>
  );
}

export default InvoiceBill;
