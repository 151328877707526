import React, { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../apiContstants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });

  const [loginUser, setLoginUser] = useState(() => {
    try {
      const storedUser = localStorage.getItem("loginUser");
      return storedUser && storedUser !== "undefined"
        ? JSON.parse(storedUser)
        : null;
    } catch (error) {
      console.error("Error parsing loginUser from localStorage:", error);
      return null;
    }
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    if (loginUser) {
      localStorage.setItem("loginUser", JSON.stringify(loginUser));
    } else {
      localStorage.removeItem("loginUser");
    }
  }, [loginUser]);

  const loginMethod = async (loginUserData) => {
    try {
      const data = new FormData();
      for (const [key, value] of Object.entries(loginUserData)) {
        data.append(key, value);
      }
      const loginResponse = await fetch(`${BASE_URL}api/auth/login`, {
        method: "POST",
        body: data,
      });
      const loginData = await loginResponse.json();
      if (loginResponse.status === 200) {
        setLoginUser(loginData?.paymentDetails);
        if (loginData.paymentDetails) {
          setIsLoggedIn(true);
          toast.success("Login Successfully");
        }
        setError("");
        // Store additional data in localStorage
        if (
          Array.isArray(loginData?.paymentDetails) &&
          loginData?.paymentDetails.length > 0
        ) {
          localStorage.setItem(
            "planType",
            JSON.stringify(
              loginData?.paymentDetails.map((user) => user.planType)
            )
          );
          localStorage.setItem(
            "shipping_customer_name",
            JSON.stringify(
              loginData?.paymentDetails.map(
                (user) => user.shipping_customer_name
              )
            )
          );
        }
        localStorage.setItem("Token", loginData.token);
        localStorage.setItem("role", loginData?.paymentDetails[0]?.role);
        return true;
      } else {
        setError(loginData?.error || "Login failed");
        return false;
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("An error occurred while logging in.");
      return false;
    }
  };

  const token = localStorage.getItem("Token");
  
  const logout = async () => {
    try {
      await axios.post(
        `${BASE_URL}api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/");
      setLoginUser(null);
      setIsLoggedIn(false);
      setError("");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("isLoggedInAdmin");
      localStorage.removeItem("loginUser");
      localStorage.removeItem("planType");
      localStorage.removeItem("planValidity");
      localStorage.removeItem("shipping_customer_name");
      localStorage.removeItem("Token");
      localStorage.removeItem("role");
      toast.success("Logout successful!");
    } catch (error) {
      if (error.response.status === 401) {
        invalidToken();
      } else {
        console.error("Error response:", error.response.data);
        alert(`Logout failed: ${error.response.data}`);
      }
    }
  };

  const invalidToken = () => {
    navigate("/login");
    setLoginUser(null);
    setIsLoggedIn(false);
    setError("");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("isLoggedInAdmin");
    localStorage.removeItem("loginUser");
    localStorage.removeItem("planType");
    localStorage.removeItem("planValidity");
    localStorage.removeItem("shipping_customer_name");
    localStorage.removeItem("Token");
    localStorage.removeItem("role");
    toast.warning("Your session expired Please Login Again !!");
  };

  return (
    <AuthContext.Provider
      value={{
        error,
        isLoggedIn,
        loginMethod,
        logout,
        loginUser,
        setLoginUser,
        invalidToken,
      }}
    >
      {children}
      <ToastContainer position="top-right" autoClose={3000} />
    </AuthContext.Provider>
  );
};

export const useUser = () => useContext(AuthContext);
