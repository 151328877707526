import React, { useState } from "react";
import LOGO from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "../css/Homepage.css";
import Profile from "./Profile";
import { useUser } from "../../authentication/AuthProvider";
import Footer from "../footer/Footer";

function HowToUse() {
  const [isFullScreen] = useState(false);
  const { isLoggedIn, loginUser } = useUser();

  const planType = localStorage.getItem("planType");
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

  return (
    <div
      className={`bg-img min-h-screen w-[100%] bg-cover bg-center ${
        isFullScreen ? "fullscreen" : ""
      }`}
    >
      <div
        className="flex flex-row justify-between md:h-24 h-16 items-center px-4 lg:px-24 md:px-9 bg-black"
        // style={{ background: "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row items-center md:gap-8 gap-4 md:pr-26 md:text-xl text-sm">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              {firstName !== null &&
                (planType.includes("Web") ||
                  planType.includes("Card and Web")) && (
                  <dl>
                    <Link
                      to="/dashboard"
                      className="Poppins text-white cursor-pointer"
                    >
                      Dashboard
                    </Link>
                  </dl>
                )}
              <Profile />
            </>
          ) : (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              <Link to="/plans" className="Poppins text-white cursor-pointer">
                Plans
              </Link>
              <Link to="/login" className="Poppins text-white cursor-pointer">
                Login
              </Link>
              <Link to="/signup" className="Poppins text-white cursor-pointer">
                Signup
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="text-white text-start px-8 pb-11 Poppins">
        <p className="text-4xl font-bold text-blue-200 my-4 text-center">How To Use</p>
        <div className="p-6">
          <h3 className="text-2xl font-bold text-orange-200 mb-4">
            Account Creation
          </h3>
          <p className="text-white mb-3">
            Sign up with your basic details to unlock access to all the services
            on this website.
          </p>
          <p className="text-white">
            Log in using the credentials you provided during sign-up. Make sure
            to remember your email ID and password for future access.
          </p>
        </div>

        <div className="p-6">
          <h3 className="text-2xl font-bold text-orange-200 mb-4">Choose a Plan</h3>
          <p className="text-white mb-3">
            Select a plan to unlock exclusive features and services tailored to
            your needs.
          </p>
          <p className="text-white mb-3">
            Before purchasing, review the plan details carefully and choose the
            one that suits you best.
          </p>
          <p className="text-white mb-3">
            Ensure you provide a valid shipping address to receive your items
            securely.
          </p>
          <p className="text-white">
            Submit accurate personal information, including your date, time, and
            place of birth, along with any other required details.
          </p>
        </div>

        <div className="p-6">
          <h3 className="text-2xl font-bold text-orange-200 mb-4">
            Explore Features
          </h3>
          <p className="text-white mb-3">
            Experience the convenience of our NFC card. Simply tap it on your
            mobile to receive your astrological insights via WhatsApp.
          </p>
          <p className="text-white mb-3">
            Discover a variety of services including Predictions, Horoscopes,
            Matchmaking, and more. Access them instantly through the dashboard
            with just a click.
          </p>
          <p className="text-white">
            Save and view your astrological data easily on your PC for future
            reference.
          </p>
        </div>

        <div className="p-6">
          <h3 className="text-2xl font-bold text-orange-200 mb-3">
            Personalized Services
          </h3>
          <p className="text-white mb-2">
            Access insights about your career, health, love, and spiritual
            journey through the dashboard.
          </p>
          <p className="text-white">
            Explore our matching service to discover how your lifestyle aligns
            with your life partner.
          </p>
        </div>

        <div className="p-6">
          <h3 className="text-2xl font-bold text-orange-200 mb-3">Get Plans</h3>
          <p className="text-white mb-2">
            Discover the benefits of our NFC card and web app. The NFC card
            allows seamless access to your personalized astrological details
            with just a tap on your mobile device. The web app complements this
            by providing a comprehensive platform to manage and explore
            additional features anytime, anywhere.
          </p>
          <p className="text-white mb-2">
            Learn about how the NFC card and web app work together to offer a
            smooth and connected experience tailored to your needs.
          </p>
          <p className="text-white">
            Upgrade your plan to unlock other features, enhanced services, and
            exclusive benefits for an even better experience.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HowToUse;
