import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAllPlans } from "./CardContext";
import { BASE_URL } from "../../../apiContstants";
import { useUser } from "../../../authentication/AuthProvider";
import { toast } from "react-toastify";

const ListOfPlanTypes = () => {
  const { allPlans, fetchAllPlans } = useAllPlans();
  const [editData, setEditData] = useState({
    planTypeId: "",
    planType: "",
    validity: "",
    actualPrice: "",
    discountPercentage: "",
    discountedPrice: "",
    offerPrice: "",
  });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { invalidToken } = useUser();

  useEffect(() => {
    if (editData.actualPrice && editData.discountPercentage) {
      const discountAmount =
        editData.actualPrice * (editData.discountPercentage / 100);
      setEditData((prevState) => ({
        ...prevState,
        discountedPrice:
          Math.round((editData.actualPrice - discountAmount) * 100) / 100,
      }));
    }
  }, [editData.actualPrice, editData.discountPercentage]);

  const handleShowModal = (plan) => {
    setSelectedPlan(plan);
    setEditData({
      planTypeId: plan.planTypeId,
      planType: plan.planType,
      validity: plan.validity,
      actualPrice: plan.actualPrice,
      discountPercentage: plan.discountPercentage,
      discountedPrice:
        Math.round(
          (plan.actualPrice -
            plan.actualPrice * (plan.discountPercentage / 100)) *
            100
        ) / 100,
    });
    setModalVisible(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditSubmit = async () => {
    try {
      const updatedPlan = {
        ...selectedPlan,
        ...editData,
      };

      const sendUpdateData = new FormData();
      sendUpdateData.append("planTypeId", updatedPlan.planTypeId);
      sendUpdateData.append("planType", updatedPlan.planType);
      sendUpdateData.append("validity", updatedPlan.validity);
      sendUpdateData.append(
        "discountPercentage",
        Number(updatedPlan.discountPercentage)
      );
      sendUpdateData.append("actualPrice", updatedPlan.actualPrice);
      sendUpdateData.append("offerPrice", updatedPlan.offerPrice);

      await axios.put(
        `${BASE_URL}api/plans/updatePlansPrice/${updatedPlan.planTypeId}`,
        sendUpdateData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Plan updated:", response.data);
      toast.info(`Plan Type ${updatedPlan.planType} Updated Successfully`);
      setModalVisible(false);
      fetchAllPlans();
      setEditData({
        planTypeId: "",
        planType: "",
        validity: "",
        actualPrice: "",
        discountPercentage: "",
        discountedPrice: "",
        offerPrice: "",
      });
    } catch (error) {
      if (error.response.status === 401) {
        invalidToken();
        alert("Your Session Expired Please Login Again");
      } else {
        // console.error("Error updating plan: ", error.response.status);
        toast.error("Failed To Update The Plan");
        alert("Failed to update the plan.");
      }
    }
  };

  const token = localStorage.getItem("Token");
  // console.log(token)

  const handleDelete = async (plan) => {
    const { planId } = plan;
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}api/plans/delete/${planId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.warning("You have successfully deleted the Plan!");
        // alert("You have successfully deleted the Plan!");
        fetchAllPlans();
      } catch (error) {
        if (error.response.status === 401) {
          invalidToken();
          alert("Your Session Expired Please Login Again");
        }
        console.error("Error updating plan: ", error.response.status);
        toast.error("Failed To Update The Plan");
        alert("Failed to update the plan.");
      }
    }
  };

  return (
    <div>
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr className="bg-sky-300 text-gray-800">
              <th className="py-2 px-4 text-left">Plan Id</th>
              <th className="py-2 px-4 text-left">Plan Validity</th>
              <th className="py-2 px-4 text-left">Plan Type</th>
              <th className="py-2 px-4 text-left">Base Price</th>
              <th className="py-2 px-4 text-left">Discount %</th>
              <th className="py-2 px-4 text-left">Offer Price</th>
              <th className="py-2 px-4 text-left">Action</th>
            </tr>
          </thead>
          <tbody className="bg-gray-100">
            {allPlans
              .slice()
              .sort((a, b) => a.planTypeId - b.planTypeId)
              .map((plan, index) => (
                <tr key={plan.planId} className="hover:bg-gray-200">
                  <td className="py-2 px-4">{plan.planTypeId}</td>
                  <td className="py-2 px-4">{plan.validity}</td>
                  <td className="py-2 px-4">{plan.planType}</td>
                  <td className="py-2 px-4">{plan.actualPrice}/-</td>
                  <td className="py-2 px-4">{plan.discountPercentage}%</td>
                  <td className="py-2 px-4">{plan.offerPrice}/-</td>
                  <td className="py-2 px-4">
                    <div className="flex space-x-2">
                      <button
                        className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
                        onClick={() => handleShowModal(plan)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white rounded py-2 px-4 hover:bg-red-600"
                        onClick={() => handleDelete(plan)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {modalVisible && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={() => setModalVisible(false)}
          />
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg w-1/3">
              <div className="flex justify-between items-center p-4 border-b">
                <h5 className="text-lg font-bold">Change Plan</h5>
                <button
                  onClick={() => setModalVisible(false)}
                  className="text-gray-500 hover:text-gray-800"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
              <div className="p-4">
                <form>
                  {/* Example input fields for modal content */}
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Plan Type
                    </label>
                    <input
                      type="text"
                      value={editData.planType}
                      onChange={(e) =>
                        setEditData({ ...editData, planType: e.target.value })
                      }
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="Plan Type"
                      disabled
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="validity"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Edit Plan Validity
                    </label>
                    <select
                      id="validity"
                      name="validity"
                      className="w-full border border-gray-300 rounded-md p-2 mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={editData.validity}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Validity</option>
                      <option value="lifetime">Lifetime</option>
                      {/* <option value="yearly">Yearly</option>
                      <option value="monthly">Monthly</option> */}
                    </select>
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="actualPrice"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Base Price
                    </label>
                    <input
                      type="number"
                      className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      name="actualPrice"
                      value={editData.actualPrice}
                      min="0"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="discountPercentage"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Discount %
                    </label>
                    <input
                      type="number"
                      className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      name="discountPercentage"
                      value={editData.discountPercentage}
                      min="0"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="discountedPrice"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Offer Price (after discount)
                    </label>
                    <input
                      type="number"
                      className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      name="discountedPrice"
                      value={editData.discountedPrice}
                      min="0"
                      disabled
                    />
                  </div>
                </form>
              </div>
              <div className="flex justify-end p-4 border-t">
                <button
                  onClick={() => setModalVisible(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
                >
                  Close
                </button>
                <button
                  onClick={handleEditSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ListOfPlanTypes;
