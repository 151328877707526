import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../apiContstants";
import { useUser } from "../../../authentication/AuthProvider";
import { toast } from "react-toastify";

const UserDetails = () => {
  const [languageFilter, setLanguageFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsersData, setAllUsersData] = useState([]);
  const [villages, setVillages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { invalidToken } = useUser();
  const itemsPerPage = 10;

  const navigate = useNavigate();

  const isLoggedInAdmin = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    if (!isLoggedInAdmin) {
      navigate("/login");
    } else {
      fetchAllUsersData();
    }
  }, [isLoggedInAdmin, navigate]);

  useEffect(() => {
    const fetchVillages = async () => {
      if (selectedUser?.pincode?.length === 6) {
        try {
          const response = await fetch(
            `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${selectedUser.pincode}`,
            {
              method: "GET",
              headers: {
                "X-RapidAPI-Host":
                  "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
                "X-RapidAPI-Key":
                  "92dd61a3abmsh30aff286f1de18bp19ce46jsnd13651517f4e",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Failed to fetch villages");
          }
          const data = await response.json();
          // console.log(data);
          setVillages(data);
        } catch (err) {
          console.error(err);
        }
      } else {
        setVillages([]);
      }
    };

    fetchVillages();
  }, [selectedUser?.pincode]);

  const token = localStorage.getItem("Token");
  // console.log(token)

  const fetchAllUsersData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/admin/TotalActiveUsersList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllUsersData(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        invalidToken();
        alert("Your Session Expired Please Login Again");
      } else {
        console.log("Error Fetching details " + error);
      }
    }
  };

  const handleFilterChange = (e) => {
    setLanguageFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = allUsersData
    .filter((user) =>
      languageFilter ? user.language === languageFilter : true
    )
    .filter(
      (user) =>
        user.firstName &&
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const handleShowModal = (user) => {
    setSelectedUser({ ...user });
    document.getElementById("userModal").classList.remove("hidden");
  };

  const handleCloseModal = () => {
    document.getElementById("userModal").classList.add("hidden");
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    let newValue = value;
    if (name === "mobileNumber") {
      if (/^\d{0,10}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    } else if (name === "pincode") {
      if (/^\d{0,6}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    }
    if (name === "uploadImage" && files.length) {
      setSelectedUser((prevUser) => ({ ...prevUser, [name]: files[0] }));
    } else {
      setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
    }
  };

  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();
      for (const key in selectedUser) {
        formData.append(key, selectedUser[key]);
      }
      await axios.put(
        `${BASE_URL}api/userProfile/update1/${selectedUser.userProfileId}/${selectedUser.planType}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("User details updated successfully!");
      // alert("User details updated successfully!");
      const updatedUsers = allUsersData.map((user) =>
        user.userProfileId === selectedUser.userProfileId ? selectedUser : user
      );
      // console.log(updatedUsers);
      setAllUsersData(updatedUsers);
      document.getElementById("userModal").classList.add("hidden");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        invalidToken();
        alert("Your Session Expired Please Login Again");
      } else {
        console.error("Error submitting form:", error);
      }
    }
  };

  const maxPage = Math.ceil(filteredUsers.length / itemsPerPage);

  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredUsers.slice(begin, end);
  };

  const next = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, Math.min(page, maxPage));
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full lg:w-9/12 md:w-9/12 sm:w-11/12 my-6">
      <div className="flex justify-end md:justify-start md:ml-4 items-center mb-3">
        <div className="flex items-center gap-2">
          <label htmlFor="filter" className="text-white font-bold mb-0">
            Filter by Language
          </label>
          <select
            id="filter"
            className="form-select bg-gray-100 border border-gray-300 rounded-md p-1 md:p-2"
            value={languageFilter}
            onChange={handleFilterChange}
          >
            {/* <option value="">All</option> */}
            <option value="en">English</option>
            {/* <option value="be">Bengali (Bangla)</option>
            <option value="hi">Hindi</option>
            <option value="ka">Kannada</option>
            <option value="ml">Malayalam</option>
            <option value="ta">Tamil</option>
            <option value="te">Telugu</option>
            <option value="sp">Spain</option>
            <option value="fr">France</option> */}
          </select>
        </div>

        <div className="ml-3 md:mr-4">
          <form role="search">
            <input
              className="form-control border border-gray-300 rounded-md p-1 md:p-2"
              type="search"
              placeholder="Search by name"
              aria-label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </form>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300 rounded-lg">
          <thead className="bg-sky-300">
            <tr>
              <th className="border border-gray-300 p-2">User Profile Id</th>
              <th className="border border-gray-300 p-2">First Name</th>
              <th className="border border-gray-300 p-2">Last Name</th>
              <th className="border border-gray-300 p-2">Mobile Number</th>
              <th className="border border-gray-300 p-2">Date of Birth</th>
              <th className="border border-gray-300 p-2">Time of Birth</th>
              <th className="border border-gray-300 p-2">Pincode</th>
              <th className="border border-gray-300 p-2">Location</th>
              <th className="border border-gray-300 p-2">Style</th>
              <th className="border border-gray-300 p-2">Language</th>
              <th className="border border-gray-300 p-2">Image</th>
              <th className="border border-gray-300 p-2">Action</th>
            </tr>
          </thead>
          <tbody className="bg-gray-100 text-center">
            {currentData().map((user) => (
              <tr key={user.userProfileId} className="border-b">
                <td className="border border-gray-300 p-2">
                  {user.userProfileId}
                </td>
                <td className="border border-gray-300 p-2">{user.firstName}</td>
                <td className="border border-gray-300 p-2">{user.lastName}</td>
                <td className="border border-gray-300 p-2">
                  {user.mobileNumber}
                </td>
                <td className="border border-gray-300 p-2">
                  {user.dateOfBirth}
                </td>
                <td className="border border-gray-300 p-2">
                  {user.timeOfBirth}
                </td>
                <td className="border border-gray-300 p-2">{user.pincode}</td>
                <td className="border border-gray-300 p-2">{user.location}</td>
                <td className="border border-gray-300 p-2">{user.style}</td>
                <td className="border border-gray-300 p-2">{user.language}</td>
                <td className="border border-gray-300 p-2">
                  <img
                    src={`data:image/png;base64,${user.uplaodImage}`}
                    alt="profilePic"
                    className="w-12 h-12 rounded-full"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <button
                    className="bg-blue-500 text-white py-1 px-3 rounded"
                    onClick={() => handleShowModal(user)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination Controls */}
        <div className="flex justify-center items-center my-4 gap-2">
          <button
            className="px-4 py-2 bg-gray-200 rounded"
            onClick={prev}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: maxPage }, (_, index) => (
            <button
              key={index + 1}
              className={`px-4 py-2 ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              } rounded`}
              onClick={() => jump(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-gray-200 rounded"
            onClick={next}
            disabled={currentPage === maxPage}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      <div
        className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 hidden"
        id="userModal"
        tabIndex="-1"
        aria-labelledby="userModalLabel"
        aria-hidden="true"
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full">
            <div className="flex justify-between items-center p-4 border-b">
              <h5 className="text-lg font-semibold" id="userModalLabel">
                Edit User Details
              </h5>
              <button
                type="button"
                className="text-gray-400 hover:text-gray-600"
                onClick={handleCloseModal}
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              {selectedUser && (
                <form>
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-md p-2 w-full"
                      id="firstName"
                      name="firstName"
                      value={selectedUser.firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-md p-2 w-full"
                      id="lastName"
                      name="lastName"
                      value={selectedUser.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="mobileNumber"
                      className="block text-sm font-medium"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      className="border border-gray-300 rounded-md p-2 w-full"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={selectedUser.mobileNumber}
                      min="0"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex justify-between">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="dateOfBirth"
                        className="block text-sm font-medium"
                      >
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded-md p-2 w-full"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={selectedUser.dateOfBirth}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 w-1/2 ml-2">
                      <label
                        htmlFor="timeOfBirth"
                        className="block text-sm font-medium"
                      >
                        Time Of Birth
                      </label>
                      <input
                        type="time"
                        className="border border-gray-300 rounded-md p-2 w-full"
                        id="timeOfBirth"
                        name="timeOfBirth"
                        value={selectedUser.timeOfBirth}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="pincode"
                        className="block text-sm font-medium"
                      >
                        Pincode
                      </label>
                      <input
                        type="number"
                        className="border border-gray-300 rounded-md p-2 w-full"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]{6}"
                        maxLength="6"
                        value={selectedUser.pincode}
                        min="0"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 w-1/2 ml-2">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium"
                      >
                        Location
                      </label>
                      <select
                        type="text"
                        className="border border-gray-300 rounded-md p-2 w-full"
                        id="location"
                        name="location"
                        value={selectedUser.location}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Select Village
                        </option>
                        {villages.length > 0 ? (
                          villages.map((village, index) => (
                            <option key={index} value={village.area}>
                              {village.area}
                            </option>
                          ))
                        ) : (
                          <option disabled value="">
                            Invalid pin code.
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="style"
                      className="block text-sm font-medium"
                    >
                      Style
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-md p-2 w-full"
                      id="style"
                      name="style"
                      value={selectedUser.style}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="language"
                      className="block text-sm font-medium"
                    >
                      Language
                    </label>
                    <select
                      id="language"
                      name="language"
                      className="bg-gray-100 border border-gray-300 rounded-md p-2 w-full"
                      value={selectedUser.language}
                      onChange={handleInputChange}
                    >
                      {/* <option value="">Select Language</option> */}
                      <option value="en">English</option>
                      {/* <option value="be">Bengali (Bangla)</option>
                <option value="hi">Hindi</option>
                <option value="ka">Kannada</option>
                <option value="ml">Malayalam</option>
                <option value="ta">Tamil</option>
                <option value="te">Telugu</option>
                <option value="sp">Spain</option>
                <option value="fr">France</option> */}
                    </select>
                  </div>
                </form>
              )}
            </div>
            <div className="flex justify-end p-4 border-t">
              <button
                type="button"
                className="bg-blue-700 text-white py-1 px-3 rounded mr-2"
                onClick={handleEditSubmit}
              >
                Update
              </button>
              <button
                type="button"
                className="bg-red-600 text-white py-1 px-3 rounded"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
