import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import InvoiceBill from "./InvoiceBill";
import axios from "axios";
import { BASE_URL } from "../../apiContstants";
import InvoiceBillCardAndWeb from "./InvoiceBillCardAndWeb";
import InvoiceBillWeb from "./InvoiceBillWeb";
import LOGO from "../../assets/images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useUser } from "../../authentication/AuthProvider";
import Profile from "../homescreen/Profile";

function OrderHistory() {
  const { isLoggedIn, invalidToken } = useUser();
  const [invoiceCard, setInvoiceCard] = useState([]);
  const [invoiceWeb, setInvoiceWeb] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // console.log(invoiceWeb);
  // console.log(invoiceCard);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const cardData = Array.isArray(invoiceCard)
    ? invoiceCard.filter((item) => item.planType === "Card")
    : [];

  const cardAndWebData = Array.isArray(invoiceCard)
    ? invoiceCard.filter((item) => item.planType === "Card and Web")
    : [];

  const finalAmountCard = cardData?.reduce((total, card) => {
    const planAmount = card?.planAmount || 0;
    const frightCharges = card?.frightCharges || 0;
    return total + planAmount + Math.round(frightCharges);
  }, 0);

  const finalAmountCardAndWeb = cardAndWebData?.reduce((total, card) => {
    const planAmount = card?.planAmount || 0;
    const frightCharges = card?.frightCharges || 0;
    return total + planAmount + Math.round(frightCharges);
  }, 0);

  const loginUser = JSON.parse(localStorage.getItem("loginUser")) || [];
  const userProfileId =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].userProfileId
      : loginUser?.userProfileId || "";

  const token = localStorage.getItem("Token");
  // console.log(token)

  useEffect(() => {
    const fetchData = async () => {
      let response1Data = [];
      let response2Data = [];
      try {
        const response1 = await axios.get(
          `${BASE_URL}api/shiprocket/fetchAllCardOrCardWebInvoices/${userProfileId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        response1Data = response1.data;
        // console.log(response1Data);
        setLoading(true);
        setInvoiceCard(response1Data);
      } catch (err) {
        if(err.response.status === 401){
          invalidToken();
          alert("Your Session Expired Please Login Again")
        }
        console.err("Error in API 1:", err);
      } finally {
        setLoading(false);
      }
      try {
        const response2 = await axios.get(
          `${BASE_URL}api/payments/webInvoice/${userProfileId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        response2Data = response2.data;
        // console.log(response2Data);
        setLoading(true);
        setInvoiceWeb(response2Data);
      } catch (err) {
        if(err.response.status === 401){
          invalidToken();
          alert("Your Session Expired Please Login Again")
        }
        // console.error("Error in API 2:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const planType = localStorage.getItem("planType");
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

      // console.log(cardData);
      // console.log(cardAndWebData);
      // console.log(invoiceWeb);
      

  return (
    <div className="bg-img h-screen bg-cover bg-center">
      <div className="flex flex-row justify-between md:h-24 h-16 items-center px-4 lg:px-24 md:px-9 bg-black">
        <div className="flex justify-center md:justify-start">
          <img src={LOGO} className="h-16 md:h-36" alt="logo" />
        </div>
        <div className="flex flex-row items-center justify-between md:gap-8 gap-4 md:pr-26 md:text-xl text-sm">
          {isLoggedIn ? (
            <>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `Poppins text-white cursor-pointer p-2 rounded ${
                    isActive ? "bg-orange-500" : ""
                  }`
                }
              >
                Home
              </NavLink>
              {firstName !== null &&
                (planType.includes("Web") ||
                  planType.includes("Card and Web")) && (
                  <dl>
                    <Link
                      to="/dashboard"
                      className="Poppins text-white cursor-pointer"
                    >
                      Dashboard
                    </Link>
                  </dl>
                )}
              <Profile />
            </>
          ) : (
            <>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `Poppins cursor-pointer p-2 rounded ${
                    isActive ? "bg-orange-500 text-white" : ""
                  }`
                }
              >
                Home
              </NavLink>
              <Link to="/plans" className="Poppins text-white cursor-pointer">
                Plans
              </Link>
              <Link to="/login" className="Poppins text-white cursor-pointer">
                Login
              </Link>
              <Link to="/signup" className="Poppins text-white cursor-pointer">
                Signup
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="p-6 bg-gray-900 rounded-lg shadow-lg m-5">
        <h3 className="text-2xl font-bold text-white mb-4 text-center">
          Order History
        </h3>
        <div className="overflow-x-auto">
          <table className="table-auto text-white w-full mt-3 rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-800 border-b">
                {["Order Date", "Name", "Plan", "Total", "Actions"].map(
                  (header, idx) => (
                    <th
                      key={idx}
                      className="py-2 px-4 border-t border-gray-700 text-left"
                    >
                      {header}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {cardData &&
                cardData.map((item, index) => (
                  <tr className="text-left border-b" key={index}>
                    <td className="py-4 px-4">
                      {item.orderDate
                        ? new Date(item.orderDate).toLocaleDateString("en-UK")
                        : "N/A"}
                    </td>
                    <td className="py-4 px-4">{`${
                      item.shipping_customer_name || ""
                    } ${item.shipping_last_name || ""}`}</td>
                    <td className="py-4 px-4">{item.planType || "N/A"}</td>
                    <td className="py-4 px-4">{finalAmountCard || "N/A"}</td>
                    <td className="py-4 px-4">
                      <PDFDownloadLink
                        document={<InvoiceBill invoiceCard={invoiceCard} />}
                        fileName={`Invoice_Card.pdf`}
                        className="text-sm bg-blue-500 text-white px-3 py-2 rounded cursor-pointer"
                      >
                        {({ loading }) =>
                          loading ? "Preparing document..." : "Download Invoice"
                        }
                      </PDFDownloadLink>
                    </td>
                  </tr>
                ))}
              {cardAndWebData &&
                cardAndWebData.map((item, index) => (
                  <tr className="text-left border-b" key={index}>
                    <td className="py-4 px-4">
                      {item.orderDate
                        ? new Date(item.orderDate).toLocaleDateString("en-UK")
                        : "N/A"}
                    </td>
                    <td className="py-4 px-4">{`${
                      item.shipping_customer_name || ""
                    } ${item.shipping_last_name || ""}`}</td>
                    <td className="py-4 px-4">{item.planType || "N/A"}</td>
                    <td className="py-4 px-4">
                      {finalAmountCardAndWeb || "N/A"}
                    </td>
                    <td className="py-4 px-4">
                      <PDFDownloadLink
                        document={
                          <InvoiceBillCardAndWeb invoiceCard={invoiceCard} />
                        }
                        fileName={`Invoice_Card and Web.pdf`}
                        className="text-sm bg-blue-500 text-white px-3 py-2 rounded"
                      >
                        {({ loading }) =>
                          loading ? "Preparing document..." : "Download Invoice"
                        }
                      </PDFDownloadLink>
                    </td>
                  </tr>
                ))}
              {invoiceWeb?.planType && (
                <tr className="text-left border-b">
                  <td className="py-4 px-4">
                    {invoiceWeb.orderDate
                      ? new Date(invoiceWeb.orderDate).toLocaleDateString(
                          "en-UK"
                        )
                      : "N/A"}
                  </td>
                  <td className="py-4 px-4">
                    {`${invoiceWeb.firstName || ""} ${
                      invoiceWeb.lastName || ""
                    }`}
                  </td>
                  <td className="py-4 px-4">{invoiceWeb.planType || "N/A"}</td>
                  <td className="py-4 px-4">
                    {invoiceWeb.paymentAmount || "N/A"}
                  </td>
                  <td className="py-4 px-4">
                    <PDFDownloadLink
                      document={<InvoiceBillWeb invoiceWeb={invoiceWeb} />}
                      fileName={`Invoice_Web.pdf`}
                      className="text-sm bg-blue-500 text-white px-3 py-2 rounded"
                    >
                      {({ loading }) =>
                        loading ? "Preparing document..." : "Download Invoice"
                      }
                    </PDFDownloadLink>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
