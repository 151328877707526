import { PlansProvider } from './Admin/component/card/CardContext';
import './App.css';
import { AuthProvider } from './authentication/AuthProvider';
import Mainroute from './routes/Mainroute';

function App() {

  return (
    <div>
      <AuthProvider>
          <PlansProvider>
            <Mainroute />
          </PlansProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
