import React, { useState } from "react";
import personal1 from "../../assets/images/personal1.png";
import weekly from "../../assets/images/weekly.png";
import smf from "../../assets/images/smf.png";
import loader from "../../assets/images/loader.gif";
import { useUser } from "../../authentication/AuthProvider";
import jsPDF from "jspdf";
import axios from "axios"; // Import Axios
import { BASE_URL } from "../../apiContstants";

const cardData = [
  {
    id: 1,
    title: "Gem Details",
    icon: weekly,
  },
  {
    id: 2,
    title: "Geo Search",
    icon: weekly,
  },
  {
    id: 3,
    title: "Radical Number Details",
    icon: smf,
  },
  {
    id: 4,
    title: "Nakshatra Vastu Details",
    icon: personal1,
  },
];

function Utility() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [utilityData, setUtilityData] = useState(null);
  const [ utilityResult, setUtilityResult ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { loginUser,invalidToken } = useUser();

  const userProfileId = Array.isArray(loginUser) && loginUser.length > 0
    ? loginUser[0].userProfileId
    : loginUser.userProfileId || '';
  
    const token = localStorage.getItem("Token");
    // console.log(token)

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setShowModal(true);
  };

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setUtilityData((prevPartner) => ({
      ...prevPartner,
      [name]: value
    }))
  }

  const closeModal = () => {
    setShowModal(false);
    setSelectedCard(null);
  };

  // const handleView = () => {
  //   setShowModal1(true);
  // };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    setLoading(true);
    setShowModal1(true);
    const selectedCardId = cardData[index]?.id;
    const selectedCardTitle = cardData[index]?.title;
    const isLongTerm = cardData[index]?.isLongTerm;

    const formatData = () => {
      const formattedUtilityData = Object.entries(utilityData).reduce((acc, [key, value]) => {
        if (key === "Radical Number Details") {
          acc["Radical_number"] = value;
        } else if(key === "Gem Details"){
          acc["Gem"] = value;
        } else if(key === "Geo Search"){
          acc["City"] = value;
        } else if(key === "Nakshatra Vastu Details"){
          acc["Nakshatra"] = value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
    
      return {
        cardId: selectedCardId,
        ...formattedUtilityData
      };
    };

    const requestData = formatData()

    try {
      const response = await axios.post(
        `${BASE_URL}api/webhook/prediction/trigger?userProfileId=${userProfileId}&title=${selectedCardTitle}&isLongTerm=${isLongTerm}`,
        // "https://hook.eu2.make.com/tvue2uj4saxl6u52wd7c5n7u495s9qmo",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUtilityData(null)
      const result = response.data;
      // console.log("API Response:", result);
      setShowModal(false)
      setUtilityResult(result);
    } catch (error) {
      // alert(console.error("Error:", error))
      setShowModal(false)
      if(error.response.status === 401)
        {
          invalidToken();
          alert("Please Login Again")
        }
    } finally{
      setLoading(false)
    }
  };

  // const handleDownload = () => {
  //   const doc = new jsPDF();

  //   const table = document.querySelector('.matching-table');
  //   if (table) {
  //     doc.html(table, {
  //       callback: function (doc) {
  //         doc.save('horoscope-data.pdf');
  //       },
  //       margin: [10, 10, 10, 10],
  //       x: 10,
  //       y: 10,
  //     });
  //   }
  // };

  const closeModal1 = () => {
    setShowModal1(false);
  };

  return (
    <div className="w-full sm:w-full lg:w-3/5">
      <div className="mt-5 flex-1">
        <h3 className="text-white font-mono Anton">Utility</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 gap-2 mt-5 font-mono">
          {cardData.map((card, index) => (
            <div className="col-span-1" key={card.id}>
              <button
                className="w-full border rounded-lg mb-3 p-2 text-white font-bold cursor-pointer bg-white bg-opacity-25 lg:bg-opacity-25 shadow-lg transition-all duration-300 ease-in-out hover:shadow-[0px_0px_6px_6px_rgba(197,132,219,0.7)] hover:border-transparent hover:bg-purple-700 hover:rounded-lg hover:text-black"
                onClick={() => handleCardClick(index)}
              >
                <div className="flex items-center gap-2 h-16 text-start">
                  <img src={card.icon} alt="icon" className="h-6 w-6" />
                  <h5 className="text-xs sm:text-sm md:text-base p-1">{card.title}</h5>
                </div>
              </button>
              {/* {selectedCard === index && utilityResult && (
                <div className="flex gap-4 mt-2">
                  <button
                    onClick={handleView}
                    className="text-xs p-2 bg-orange-500 text-white rounded-lg shadow-sm hover:bg-orange-700"
                  >
                    View Details
                  </button>
                </div>
              )} */}
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg mx-4">
            <form className="mt-4 sm:px-12" onSubmit={(e) => handleSubmit(e, selectedCard)}>
              <h3 className="text-lg font-semibold mb-4">
                {cardData[selectedCard].title} - Details
              </h3>
              <div className="text-[20px] px-2 mb-4">
                <label>
                {cardData[selectedCard].title}
                  <input
                    type="text"
                    name={cardData[selectedCard].title}
                    className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                    placeholder={selectedCard !== null ? `Enter ${cardData[selectedCard].title}` : "Enter details"}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                    Select Language
                    <br />
                    <select
                      name="language"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 py-4 outline-none"
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Language</option>
                      <option value="en">English</option>
                    </select>
                </label>
              </div>
              <div className="text-[18px] flex justify-end gap-2 mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-700"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg shadow-sm hover:bg-red-700 mr-2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showModal1 && utilityResult && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
          {loading ? (
          <div className="flex justify-center items-center mt-4">
            <img src={loader} alt="loader" className="h-16 w-16" />
          </div>
        ) : (
          <>
            <h2 className="font-semibold mb-4">
            {Array.isArray(loginUser) && loginUser.length > 0
              ? loginUser[0].userName
              : loginUser.userName || ''} Utility Details
            </h2>

            <div className="w-full max-w-7xl max-h-96  overflow-y-auto">
              <table className="w-full border-collapse matching-table">
                <tbody>
                  {Object.entries(utilityResult).map(([key, value]) => (
                    <React.Fragment key={key}>
                      {/* <tr className="border-b">
                        <td className="p-2 font-semibold">{key.replace(/_/g, ' ')}</td> */}
                      <td className="p-2">
                        {typeof value === 'string' ? (
                          value
                        ) : (
                          <table className="w-full">
                            <tbody>
                              {Object.entries(value).map(([subKey, subValue]) => (
                                <tr key={subKey}>
                                  <td className="p-2 font-semibold">{subKey}</td>
                                  <td className="p-2">{subValue}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </td>
                      {/* </tr> */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <button
              onClick={handleDownload}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700"
            >
              Download
            </button> */}

            <button
              onClick={closeModal1}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700 float-right"
            >
              Close
            </button>
          </>
        )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Utility;
