import React, { useEffect, useState } from "react";
import { useUser } from "../../authentication/AuthProvider";
import axios from "axios";
import jsPDF from "jspdf";
import { BASE_URL } from "../../apiContstants";

function DownloadPDF({data}) {
  const [user, setUser] = useState(null)
  const [match, setMatch] = useState(null)
  const { loginUser, invalidToken } = useUser()
  const [handleShowModal, setHandleShowModal] = useState(false);
  const [horoscopeLoading, setHoroscopeLoading] = useState(false);

  const userProfileId = Array.isArray(loginUser) && loginUser.length > 0
    ? loginUser[0].userProfileId
    : loginUser.userProfileId || '';

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    const date = new Date(`1970-01-01T${timeString}`);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}api/userProfile/webhook/${userProfileId}`
  //       );
  //       const data = response.data;
  //       setUser((prevUser) => ({
  //         ...prevUser,
  //         Lang: data.language,
  //         Dob: formatDate(data.dateOfBirth),
  //         Tob: formatTime(data.timeOfBirth),
  //         Tz: data.timeZone,
  //         Lat: data.latitude,
  //         Lon: data.longitude,
  //         Pob: data.location,
  //         Style: data.style,
  //         Name: `${data.firstName} ${data.lastName}`
  //       }));
  //     } catch (error) {
  //       console.log("fetching data: Wbhk");
  //     }
  //   };

  //   if (userProfileId) {
  //     fetchData();
  //   }
  // }, [userProfileId]);

  useEffect(() => {
    if(data){
        setUser((prevUser) => ({
          ...prevUser,
          Lang: data.language,
          Dob: formatDate(data.dateOfBirth),
          Tob: formatTime(data.timeOfBirth),
          Tz: data.timeZone,
          Lat: data.latitude,
          Lon: data.longitude,
          Pob: data.location,
          Style: data.style,
          Name: `${data.firstName} ${data.lastName}`
        }));
      } 

  }, []);

  const token = localStorage.getItem("Token");
  // console.log(token)

  const handleHoroscope = async () => {
    setHoroscopeLoading(true)
    const requestData = {
      lang: user.Lang,
      dob: user.Dob,
      tob: user.Tob,
      lat: user.Lat,
      lon: user.Lon,
      pob: user.Pob,
      tz: user.Tz,
      style: user.Style,
      service: "lifetime",
      userName: user.Name,
    }
    // console.log(requestData)
    try {
      const response = await axios.post(
        `${BASE_URL}api/lifeTimeHoroscope/Save?userProfileId=${userProfileId}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      // console.log("API Response:", result);
      window.open(result, "_blank")
    } 
    catch (error) {
      console.log("Error: horo");
      if(error.response.status === 401)
      {
        invalidToken();
        alert("Please Login Again")
      }
    } finally{
      setHoroscopeLoading(false)
    }
  }

  const handleMatching = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/matching/fetchedMatchingLastData/${userProfileId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        // "https://hook.eu2.make.com/tvue2uj4saxl6u52wd7c5n7u495s9qmo",
      );
      const result = response.data;
      // console.log("API Response:", result);
      setMatch(result)
      setHandleShowModal(true)
    } catch (error) {
      if(error.response.status === 401){
        invalidToken();
        alert("Please Login Again")
      } else {
        console.log("Error: mtch");
        alert(error.response.data.message)
      }
    }
  }

  const closeModal1 = () => {
    setHandleShowModal(false);
  };

  const handleDownload = () => {
    const doc = new jsPDF();

    const table = document.querySelector('.matching-table');
    if (table) {
      doc.html(table, {
        callback: function (doc) {
          doc.save('horoscope-data.pdf');
        },
        margin: [10, 10, 10, 10],
        x: 10,
        y: 10,
      });
    }
  };

  return (
    <div className="w-full sm:w-full lg:w-1/2 xl:text-2xl">
      <section className="items-center font-mono">
        <h2 className="text-white mt-6 text-center Anton">Download PDF</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 mt-5">
          <button onClick={handleHoroscope} className="p-3 bg-orange-200 rounded-lg shadow-sm hover:bg-orange-300" disabled={horoscopeLoading}>
            { !horoscopeLoading ? "Horoscope" : "Please Wait" }
          </button>
          <button onClick={handleMatching} className="p-3 bg-orange-200 rounded-lg shadow-sm hover:bg-orange-300">
            Matching
          </button>
        </div>
        {handleShowModal && user && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-full lg:w-2/3 sm:w-11/12 p-4 rounded-lg shadow-lg mx-6">
              <h2 className="font-semibold mb-4">{Array.isArray(loginUser) && loginUser.length > 0
                ? loginUser[0].userName
                : loginUser.userName || ''} Matching</h2>

              <div className="w-full max-w-7xl max-h-96  overflow-y-auto">
                <table className="w-full border-collapse matching-table">
                  <tbody>
                    {Object.entries(match).map(([key, value]) => (
                      <React.Fragment key={key}>
                        <tr className="border-b">
                          <td className="p-2 font-bold text-sm sm:text-base md:text-base lg:text-lg xl:text-xl">{key.replace(/_/g, ' ')}</td>
                          <td className="p-2 text-sm sm:text-base md:text-base lg:text-lg xl:text-xl">
                            {typeof value === 'string' ? (
                              value
                            ) : (
                              <table className="w-full">
                                <tbody>
                                  {Object.entries(value).map(([subKey, subValue]) => (
                                    <tr key={subKey}>
                                      <td className="p-2 font-semibold">{subKey}</td>
                                      <td className="p-2">{subValue}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                onClick={handleDownload}
                className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700"
              >
                Download
              </button>

              <button
                onClick={closeModal1}
                className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700 float-right"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default DownloadPDF;
