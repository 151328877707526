import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../../apiContstants";
import { useUser } from "../../../authentication/AuthProvider";
import { toast } from "react-toastify";

const AddressList = () => {
  const [addressList, setAddressList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const { invalidToken } = useUser();
  // console.log(currentAddress)

  const [formData, setFormData] = useState({
    billingCustomerName: "",
    billingLastName: "",
    billingAddress: "",
    billingAddress2: "",
    billingCity: "",
    billingPincode: "",
    billingState: "",
    billingCountry: "",
    billingEmail: "",
    billingPhone: "",
  });  

  const handleAdd = () => {
    setIsEdit(false);
    setFormData({
      billingCustomerName: "",
      billingLastName: "",
      billingAddress: "",
      billingAddress2: "",
      billingCity: "",
      billingPincode: "",
      billingState: "",
      billingCountry: "",
      billingEmail: "",
      billingPhone: "",
    });
    setShowModal(true);
  };
  const token = localStorage.getItem("Token");
  const modalRef = useRef();
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const responseAdd = await axios.get(`${BASE_URL}api/billingAddressForShipping/fetchAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(responseAdd);
        setAddressList(responseAdd.data)
      } catch (error) {
        if(error.response.status === 401){
          invalidToken();
          alert("Your Session Expired Please Login Again")
        }
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [])

  const handleEdit = (bId) => {
    const index = addressList.findIndex(item => item.bId === bId);
    setIsEdit(true);
    setCurrentAddress(index);
    setFormData(addressList[index]);
    setShowModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (isEdit) {
        response = await axios.put(
          `${BASE_URL}api/billingAddressForShipping/updateBillingAddress/${formData.bId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.info("Address Modified Successfully!!")
          const updatedList = [...addressList];
          updatedList[currentAddress] = formData;
          setAddressList(updatedList);
        }
      } else {
        response = await axios.post(
          `${BASE_URL}api/billingAddressForShipping/saveBillingAddress`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // if (response.status === 200) {
          toast.success("Address Saved Successfully!!")
          setAddressList([...addressList, response.data]);
        // }
      }
      setShowModal(false);
    } catch (error) {
      if(error.response.status === 401)
      {
        invalidToken();
        alert("Your Session Expired Please Login Again")
      }
      console.error('Error occurred', error);
    }
  };

  const handleDeleteTable = async (bId) => {
    try {  
      const response = await axios.delete(
        `${BASE_URL}api/billingAddressForShipping/deleteByBillingAddressId/${bId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.warning("Address Deleted Successfully!!")
      }
      setAddressList((prevList) => prevList.filter((item) => item.bId !== bId));
    } catch (error) {
      if(error.response.status === 401){
        invalidToken();
        alert("Your Session Expired Please Login Again")
      }else {
        toast.error('Failed to delete the address. Please try again.')
        alert('Failed to delete the address. Please try again.');
      }
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "billingPhone") {
      if (/^\d{0,10}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    } else if (name === "billingPincode") {
      if (/^\d{0,6}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="my-6 w-full lg:w-9/12 md:w-9/12 sm:w-11/12">
      <button
        onClick={handleAdd}
        className="px-4 py-2 bg-blue-500 text-white rounded-md mb-4 w-1/4"
      >
        Add Address
      </button>
      
      <div className="overflow-x-auto rounded-lg">
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-sky-300">
            <tr>
              <th className="border border-gray-300 px-4 py-2">Sl No</th>
              <th className="border border-gray-300 px-4 py-2">First Name</th>
              <th className="border border-gray-300 px-4 py-2">Last Name</th>
              <th className="border border-gray-300 px-4 py-2">Address</th>
              <th className="border border-gray-300 px-4 py-2">
                Address 2
              </th>
              <th className="border border-gray-300 px-4 py-2">City</th>
              <th className="border border-gray-300 px-4 py-2">Pincode</th>
              <th className="border border-gray-300 px-4 py-2">State</th>
              <th className="border border-gray-300 px-4 py-2">Country</th>
              <th className="border border-gray-300 px-4 py-2">Email</th>
              <th className="border border-gray-300 px-4 py-2">Phone</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {addressList.map((item, index) => (
              <tr key={index}>
                <td className="border border-gray-300 px-4 py-2">
                  {item.bId}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingCustomerName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingLastName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingAddress}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingAddress2}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingCity}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingPincode}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingState}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingCountry}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingEmail}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item.billingPhone}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    onClick={() => handleEdit(item.bId)}
                    className="px-4 py-1 bg-yellow-500 text-white rounded-md mr-2 mb-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteTable(item.bId)}
                    className="px-4 py-1 bg-red-600 text-white rounded-md shadow-md hover:bg-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            {addressList.length === 0 && (
              <tr>
                <td colSpan="12" className="text-center font-semibold py-4">
                  No Address found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Add/Edit Address */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div ref={modalRef} className="bg-white p-6 rounded-md w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3">
            <h3 className="text-xl mb-4">
              {isEdit ? "Edit Address" : "Add Address"}
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="billingCustomerName"
                    value={formData.billingCustomerName}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="billingLastName"
                    value={formData.billingLastName}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <textarea
                    name="billingAddress"
                    value={formData.billingAddress}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    rows="5"
                    required
                  ></textarea>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Address Line 2(Optional)
                  </label>
                  <textarea
                    name="billingAddress2"
                    value={formData.billingAddress2}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    type="text"
                    name="billingCity"
                    value={formData.billingCity}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Pincode
                  </label>
                  <input
                    type="number"
                    name="billingPincode"
                    value={formData.billingPincode}
                    onChange={handleChange}
                    pattern="[0-9]{6}"
                    maxLength="6"
                    min="0"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <input
                    type="text"
                    name="billingState"
                    value={formData.billingState}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Country
                  </label>
                  <input
                    type="text"
                    name="billingCountry"
                    value={formData.billingCountry}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="billingEmail"
                    value={formData.billingEmail}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="billingPhone"
                    value={formData.billingPhone}
                    onChange={handleChange}
                    pattern="[0-9]{10}"
                    maxLength="10"
                    min="0"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>
              <div className="text-center flex justify-center gap-2">
                <button
                  type="submit"
                  className="px-6 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700"
                >
                  {isEdit ? "Save Edit Address" : "Add Address"}
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressList;
