import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LOGO from "../assets/images/logo.png";
import Profile from "../components/homescreen/Profile";
import "../dashboard/dashboard.css";
import Prediction from "./components/Prediction";
import Horoscope from "./components/Horoscope";
import Matching from "./components/Matching";
import Dashas from "./components/Dashas";
import Dosha from "./components/Dosh";
import Utility from "./components/Utility";
import DownloadPDF from "./components/DownloadPDF";
import { useUser } from "../authentication/AuthProvider";
import axios from "axios";
import { BASE_URL } from "../apiContstants";

function Dashboard() {
  const { isLoggedIn, loginUser, invalidToken } = useUser();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  const userProfileId =
    Array.isArray(loginUser) && loginUser?.length > 0
      ? loginUser[0]?.userProfileId
      : loginUser?.userProfileId || "";

  const token = localStorage.getItem("Token");
  // console.log(token)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/login");
  //   }
  // }, [isLoggedIn, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${BASE_URL}api/userProfile/webhook/${userProfileId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        // console.log(data)
        setData(data);
        setLoading(false);
      } catch (error) {
        console.log("fetching data: Wbhk");
        if (error.response.status === 401) {
          invalidToken();
          alert("Please Login Again");
        }
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    if (userProfileId) {
      fetchData();
    }

    if (!isLoggedIn) {
      navigate("/login");
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userProfileId, isLoggedIn, navigate]);

  let userStatus = null;

  if (Array.isArray(loginUser) && loginUser.length > 0) {
    userStatus = loginUser[0].status;
  } else if (loginUser && typeof loginUser === "object") {
    userStatus = loginUser.status;
  }

  return (
    <div
      className={
        !isMobile
          ? "bgDB-img bg-fixed  min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]"
          : "bgDBT-img bg-fixed  min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]"
      }
    >
      <div
        className="flex flex-row justify-between h-16 md:h-24 items-center px-4 md:px-2 lg:px-24 sticky top-0 z-20 bg-black"
        // style={{ background: "rgb(13 25 73)" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-12 md:h-16 lg:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row items-center justify-between gap-4 md:gap-8 text-sm md:text-xl text-white">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `Poppins cursor-pointer p-2 rounded ${
                    isActive ? "bg-orange-500 text-white" : ""
                  }`
                }
              >
                Dashboard
              </NavLink>
              <Profile />
            </>
          ) : (
            <Link to="/login" className="Poppins cursor-pointer">
              Login
            </Link>
          )}
          {!isLoggedIn && (
            <Link to="/signup" className="Poppins cursor-pointer">
              Signup
            </Link>
          )}
        </div>
      </div>
      {loading ? (
        <p className="text-white text-3xl text-center">Please wait loading</p>
      ) : (
        <div>
          {userStatus !== null && userStatus !== 0 ? (
            <div>
              <h1 className="sm:w-full lg:w-full text-white text-center mt-8 md:mt-12 lg:mt-5 text-2xl md:text-4xl lg:text-4xl px-4 md:px-9 lg:px-24 animate-slide-in font-mono font-bold">
                Welcome to Your Destiny Finder
              </h1>
              <div>
                <div className="w-full lg:flex-row lg:gap-8 items-start px-4 md:px-9 lg:px-24 lg:text-4xl sm:text-2xl">
                  <Prediction data={data} />
                  <Horoscope data={data} />
                  <Matching data={data} />
                  <Dashas data={data} />
                  <Dosha data={data} />
                  <Utility data={data} />
                  <DownloadPDF data={data} />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center sm:w-full lg:w-1/2 mt-9">
              <h1 className="text-white mt-8 md:mt-12 lg:mt-8 text-2xl md:text-4xl lg:text-5xl px-4 md:px-9 lg:px-24 font-bold text-center animate-slide-in">
                Please Choose a Plan
              </h1>
              <Link
                to="/plans"
                className="mt-8 px-6 py-3 text-lg md:text-xl lg:text-2xl font-semibold text-white-700 bg-red-50 shadow-lg shadow-red-500/50 hover:bg-gray-100 transition-colors duration-300 rounded-lg shadow-lg"
              >
                Go To Plan Page
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
