import React, { useEffect, useState } from "react";
import personal1 from "../../assets/images/personal1.png";
import planet1 from "../../assets/images/planet1.png";
import weekly from "../../assets/images/weekly.png";
import smf from "../../assets/images/smf.png";
import loader from "../../assets/images/loader.gif";
import { useUser } from "../../authentication/AuthProvider";
import axios from "axios";
import { BASE_URL } from "../../apiContstants";

const cardData = [
  {
    id: 1 ,
    title: "Mangal Dosh",
    icon: weekly,
  },
  {
    id: 2 ,
    title: "Kaalsarp Dosh",
    icon: weekly,
  },
  {
    id: 3 ,
    title: "Mangalik Dosh",
    icon: smf,
  },
  {
    id: 4 ,
    title: "Pitra Dosh",
    icon: personal1,
  },
  {
    id: 5 ,
    title: "Papasamay",
    icon: planet1
  },
];

const zodiacSigns = {
  Aries: 1,
  Taurus: 2,
  Gemini: 3,
  Cancer: 4,
  Leo: 5,
  Virgo: 6,
  Libra: 7,
  Scorpio: 8,
  Sagittarius: 9,
  Capricorn: 10,
  Aquarius: 11,
  Pisces: 12,
};

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

const getWeekOfMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const pastDaysOfMonth = (date - firstDayOfMonth) / 86400000;
  return Math.ceil((pastDaysOfMonth + firstDayOfMonth.getDay() + 1) / 7);
};

const weekOfTheMonth = getWeekOfMonth(currentDate);


function Dosha({data}) {
  // const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { loginUser, invalidToken } = useUser();
  const [user, setUser] = useState({
    Date: formattedDate,
    Zodiac: null,
    show_same: true,
    split: true,
    Lang: null,
    Week: weekOfTheMonth,
    Year: new Date().getFullYear(),
    Dob: null,
    Tob: null,
    Lat: null,
    Lon: null,
    Tz: "",
  });
  const [ responseData, setResponseData ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const userProfileId = Array.isArray(loginUser) && loginUser.length > 0 
    ? loginUser[0].userProfileId 
    : loginUser.userProfileId || '';

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}api/userProfile/webhook/${userProfileId}`
  //       );
  //       const data = response.data;

  //       const zodiacNumber = zodiacSigns[data.zodiac] || null;

  //       setUser((prevUser) => ({
  //         ...prevUser,
  //         Zodiac: zodiacNumber,
  //         Lang: data.language,
  //         Dob: data.dateOfBirth,
  //         Tob: data.timeOfBirth,
  //         Tz: data.timeZone,
  //         Lat: data.latitude,
  //         Lon: data.longitude,
  //         Style: data.style
  //       }));
  //     } catch (error) {
  //       console.log("Error fetching data: Wbhk");
  //     }
  //   };

  //   if (userProfileId) {
  //     fetchData();
  //   }
  // }, [userProfileId]);

  useEffect(() => {
    if(data){
        const zodiacNumber = zodiacSigns[data.zodiac] || null;
        setUser((prevUser) => ({
          ...prevUser,
          Zodiac: zodiacNumber,
          Lang: data.language,
          Dob: data.dateOfBirth,
          Tob: data.timeOfBirth,
          Tz: data.timeZone,
          Lat: data.latitude,
          Lon: data.longitude,
          Style: data.style
        }));
      } 

  }, []);

  const token = localStorage.getItem("Token");
  // console.log(token)

  const handleCardClick = async (index) => {
    setLoading(true);
    setShowModal(true);
    const selectedCardId = cardData[index].id;
    const selectedCardTitle = cardData[index].title;
    const selectedCardType = cardData[index].Type;
    const isLongTerm = cardData[index].isLongTerm;
    // setSelectedCard(index);

    const updatedUser = {
      ...user,
      Date: formattedDate,
      Week: weekOfTheMonth,
      Title: selectedCardTitle,
      Type: selectedCardType,
    };
    setUser(updatedUser);

    let requestData = {};

    switch (selectedCardId) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        requestData = {
          cardId: selectedCardId,
          Dob: user.Dob,
          Tob: user.Tob,
          Lat: user.Lat,
          Lon: user.Lon,
          Tz: user.Tz,
          Lang: user.Lang,
          service: updatedUser.Title,
        };
        break;
      default:
        return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}api/webhook/prediction/trigger?userProfileId=${userProfileId}&title=${selectedCardTitle}&isLongTerm=${isLongTerm}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = response.data;
      // console.log("API Response:", result);
      setResponseData(result)
    } catch (error) {
      if(error.response.status === 401){
        invalidToken();
        alert("Please Login Again")
      } else {
        console.log("Error:Dosh");
      }
    } finally{
      setLoading(false)
    }
  };

  // const handleView = () => {
  //   setShowModal(true);
  // };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full sm:w-full lg:w-3/5">
      <div className="mt-5 flex-1">
        <h3 className="text-white font-mono Anton">Dosh</h3>
        <div
            className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2 mt-5 font-mono"
          >
            {cardData.map((card, cardIndex) => (
            <div className="col-span-1" key={cardIndex}>
              <button
                className="w-full border rounded-lg mb-3 p-2 text-white font-bold cursor-pointer bg-white bg-opacity-25 lg:bg-opacity-25 shadow-lg transition-all duration-300 ease-in-out hover:shadow-[0px_0px_6px_6px_rgba(197,132,219,0.7)] hover:border-transparent hover:bg-purple-700 hover:rounded-lg hover:text-black"
                onClick={() => handleCardClick(cardIndex)}
              >
                <div className="flex items-center gap-2 h-16 text-start">
                  <img src={card.icon} alt="icon" className="h-6 w-6" />
                  <h5 className="text-xs sm:text-sm md:text-base p-1">{card.title}</h5>
                </div>
              </button>
              {/* {selectedCard === cardIndex && (
                <div className="flex gap-4 mt-2">
                  <button
                    onClick={handleView}
                    className="text-xs p-2 bg-orange-500 text-white rounded-lg shadow-sm hover:bg-orange-700"
                  >
                    View Details
                  </button>
                </div>
              )} */}
            </div>
          ))}
          </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
          {loading ? (
          <div className="flex justify-center items-center mt-4">
            <img src={loader} alt="loader" className="h-16 w-16" />
          </div>
        ) : (
          <>
            <h2 className="font-semibold mb-4">{Array.isArray(loginUser) && loginUser.length > 0
              ? loginUser[0].userName
              : loginUser.userName || ''} Horoscope</h2>

            <div className="w-full max-w-7xl max-h-96  overflow-y-auto">
              <table className="w-full border-collapse">
                <tbody>
                  {Object.entries(responseData).map(([key, value]) => (
                    <React.Fragment key={key}>
                      {/* <tr className="border-b">
                        <td className="p-2 font-semibold">{key.replace(/_/g, ' ')}</td> */}
                        <td className="p-2">
                          {typeof value === 'string' ? (
                            value
                          ) : (
                            <table className="w-full">
                              <tbody>
                                {Object.entries(value).map(([subKey, subValue]) => (
                                  <tr key={subKey}>
                                    <td className="p-2 font-semibold">{subKey}</td>
                                    <td className="p-2">{subValue}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </td>
                      {/* </tr> */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            <button
              onClick={closeModal}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700 float-right"
            >
              Close
            </button>
          </>
        )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dosha;
