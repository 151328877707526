import React, { useState } from "react";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../apiContstants";
import { useUser } from "./AuthProvider";
import { toast } from "react-toastify";

function ForgetPassword() {
  const [forgot, setForgot] = useState({
    email: "",
    otp: "",
    newPassword: "",
    showOtpInput: false,
    error: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { invalidToken } = useUser();
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!forgot.email) {
      setForgot((prevForgot) => ({
        ...prevForgot,
        error: "Please enter your email address.",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}api/auth/forgotPasswordSendOTP/${forgot.email}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setForgot((prevForgot) => ({
          ...prevForgot,
          showOtpInput: true,
          error: "",
        }));
      } else {
        setForgot((prevForgot) => ({
          ...prevForgot,
          error: "Please enter correct email.",
        }));
      }
      toast.info("OTP Sent to your Mail I'd");
    } catch (error) {
      if (error.response.status === 401) {
        invalidToken();
        alert("Your Session Expired Please Login Again");
      } else {
        setForgot((prevForgot) => ({
          ...prevForgot,
          error: "Something went wrong. Please try again.",
        }));
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (!forgot.otp || !forgot.newPassword) {
      setForgot((prevForgot) => ({
        ...prevForgot,
        error: "Please enter the OTP and your new password.",
      }));
      return;
    }

    if (!validatePassword(forgot.newPassword)) {
      setForgot((prevForgot) => ({
        ...prevForgot,
        error:
          "Password must be at least 8 characters long, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.",
      }));
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", forgot.email);
      formData.append("otp", forgot.otp);
      formData.append("newPassword", forgot.newPassword);

      const response = await fetch(`${BASE_URL}api/auth/forgotPassword`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("Password reset successfully !");
        navigate("/login");
      } else {
        setForgot((prevForgot) => ({
          ...prevForgot,
          error:
            "Failed to reset password. Please check your OTP and try again.",
        }));
      }
    } catch (error) {
      if (error.response.status === 401) {
        invalidToken();
        alert("Your Session Expired Please Login Again");
      } else {
        console.error("Error verifying OTP:", error);
        setForgot((prevForgot) => ({
          ...prevForgot,
          error: "Something went wrong. Please try again.",
        }));
      }
    }
  };

  const handleChangeForgot = (e) => {
    const { name, value } = e.target;
    setForgot((prevForgot) => ({
      ...prevForgot,
      [name]: value,
      error: "",
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]">
      <div className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-9 bg-black">
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row md:gap-24 gap-4 pr-26 md:text-xl text-sm">
          <Link to="/" className="Poppins text-white cursor-pointer">
            Home
          </Link>
        </div>
      </div>

      <div className="flex items-center justify-center xl:mt-5 mt-[8vh]">
        <div className="bg-white xl:w-[28%] lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative mt-9">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />
          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Reset Your Password
          </h1>
          <form
            className="mt-4 px-12"
            onSubmit={forgot.showOtpInput ? handleOtpSubmit : handleEmailSubmit}
          >
            <input
              type="email"
              name="email"
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
              placeholder="Enter email to get OTP"
              value={forgot.email}
              onChange={handleChangeForgot}
              disabled={forgot.showOtpInput}
            />
            {forgot.showOtpInput && (
              <>
                <input
                  type="number"
                  name="otp"
                  className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                  placeholder="Enter OTP"
                  value={forgot.otp}
                  onChange={handleChangeForgot}
                />
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                    placeholder="Enter New Password"
                    value={forgot.newPassword}
                    onChange={handleChangeForgot}
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-500 hover:text-indigo-500 focus:outline-none"
                    onClick={() => togglePasswordVisibility("newPassword")}
                    aria-label={
                      showPassword.newPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  >
                    <i
                      className={`fas ${
                        showPassword.newPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </button>
                </div>
              </>
            )}
            {forgot.error && (
              <p className="text-red-500 text-sm mt-1">{forgot.error}</p>
            )}
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="w-full rounded-md py-2 text-white font-medium Poppins"
                style={{ background: "#000134", fontSize: "15px" }}
              >
                {forgot.showOtpInput ? "Submit OTP" : "Send OTP"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
