import React, { useState, useEffect } from "react";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useUser } from "./AuthProvider";
import loader from "../assets/images/loader1.gif";

function Login() {
  const [login, setLogin] = useState({
    email: "",
    password: "",
    error: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    loginMethod: authenticate,
    loginUser,
    error,
    isLoggedIn,
    isLoggedInAdmin,
  } = useUser();
  const navigate = useNavigate();

  // console.log(loginUser)

  useEffect(() => {
    if (Array.isArray(loginUser) && loginUser.length > 0) {
      const { status, firstName, role, planType } = loginUser[0];
      if (isLoggedIn && role === "ROLE_ADMIN") {
        navigate("/admindashboard");
      } else if (isLoggedIn && status === "Success" && !firstName) {
        navigate("/customerData");
      } else if (
        isLoggedIn &&
        role === "ROLE_USER" &&
        (planType === "Web" || planType === "Card and Web")
      ) {
        navigate("/dashboard");
      } else if (isLoggedIn && role === "ROLE_USER") {
        navigate("/");
      }
    }
  }, [loginUser]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
      error: "",
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const passwordValidation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const onlyCharacters = /^[A-Za-z]+$/;
    const onlyNumbers = /^[0-9]+$/;

    if (!login.email || !login.password) {
      setLogin({
        ...login,
        error: "All fields are mandatory",
      });
      return;
    }
    if (
      !passwordValidation &&
      onlyCharacters &&
      onlyNumbers.test(login.password)
    ) {
      setLogin({
        ...login,
        error:
          "Password must be at least 8 characters, include at least 1 uppercase, 1 lowercase, 1 number, and 1 special character",
      });
      return;
    }

    const { email, password } = login;
    const loginData = { email, password };

    try {
      const response = await authenticate(loginData);

      if (!response) {
        setLogin({
          ...login,
          error: error,
        });
      }
    } catch (err) {
      setLogin({
        ...login,
        error: "Login failed",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-img h-screen bg-cover bg-center">
      <div className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-18 bg-black">
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row items-center md:gap-8 gap-4 pr-26 md:text-xl text-sm">
          <Link to="/" className="Poppins text-white cursor-pointer">
            Home
          </Link>
          <Link to="/plans" className="Poppins text-white cursor-pointer">
            Plans
          </Link>
          <NavLink
            to="/login"
            className={({ isActive }) =>
              `Poppins cursor-pointer p-2 rounded ${
                isActive ? "bg-orange-500 text-white" : ""
              }`
            }
          >
            Login
          </NavLink>
          <Link to="/signup" className="Poppins text-white cursor-pointer">
            Signup
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center xl:mt-20 sm:mt-20 mt-[8vh]">
        <div className="bg-white xl:w-1/3 lg:w-[40%] md:w-2/4 md:mx-0 mx-4 w-full py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your icon"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />
          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Login
          </h1>
          {loading ? (
            <img src={loader} alt="loader" />
          ) : (
            <form onSubmit={handleLogin}>
              <div className="flex justify-center mt-6 px-12">
                <input
                  type="email"
                  className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                  placeholder="Enter email"
                  name="email"
                  required
                  value={login.email}
                  onChange={handleChange}
                />
              </div>
              <div className="relative flex justify-center mt-4 px-12">
                <input
                  type={showPassword ? "text" : "password"}
                  className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                  placeholder="Enter password"
                  name="password"
                  required
                  value={login.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute right-12 p-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-indigo-500 focus:outline-none"
                  onClick={togglePasswordVisibility}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  <i
                    className={`fas ${
                      showPassword ? "fa-eye-slash" : "fa-eye"
                    }`}
                  />
                </button>
              </div>
              {error && (
                <p className="text-red-500 text-center mt-4 px-2">{error}</p>
              )}
              <p className="text-center mt-4">
                <Link
                  to="/forgotPassword"
                  className="text-blue-500 hover:underline"
                >
                  Forgot Password?
                </Link>
              </p>
              <div className="flex justify-center px-12 my-4">
                <button
                  type="submit"
                  style={{ background: "#000134", fontSize: "15px" }}
                  className="w-full rounded-md py-2 text-white font-medium Poppins"
                >
                  Login
                </button>
              </div>
              <div className="text-center">
                Doesn't have an account ?
                <Link to="/signup" className="text-blue-500 underline">
                  {" "}
                  Signup
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
