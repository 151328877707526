import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import LOGO from "../../assets/images/logo.png";
import LOGO_ICON from "../../assets/images/login icon.png";
import Profile from "../homescreen/Profile";
import { useUser } from "../../authentication/AuthProvider";
import Card from "./Card";
import Web from "./Web";
import Premium from "./Premium";

function Plans() {
  const { isLoggedIn, loginUser } = useUser();
  const [planValidity, setPlanValidity] = useState("lifetime");

  localStorage.setItem("planValidity", planValidity);

  const handlePlanType = (e) => {
    setPlanValidity(e.target.value);
  };

  const planType = localStorage.getItem("planType");
  // console.log(planType)
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

  return (
    <div className="bg-img min-h-screen w-[100%] bg-cover bg-center">
      <div
        className="flex flex-row justify-between h-16 md:h-24 items-center px-4 md:px-2 lg:px-24 sticky top-0 z-20 bg-black"
        // style={{ background: isLoggedIn ? "rgb(13 25 73)" : "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-12 md:h-16 lg:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row items-center gap-4 md:gap-8 lg:gap-8 text-sm md:text-base lg:text-xl text-white">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              {firstName !== null &&
                (planType.includes("Web") ||
                  planType.includes("Card and Web")) && (
                  <dl>
                    <Link
                      to="/dashboard"
                      className="Poppins text-white cursor-pointer"
                    >
                      Dashboard
                    </Link>
                  </dl>
                )}
              <Profile />
            </>
          ) : (
            <>
              <Link to="/" className="Poppins cursor-pointer">
                Home
              </Link>
              <NavLink
                to="/plans"
                className={({ isActive }) =>
                  `Poppins cursor-pointer p-2 rounded ${
                    isActive ? "bg-orange-500" : ""
                  }`
                }
              >
                Plans
              </NavLink>
              <Link to="/login" className="Poppins cursor-pointer">
                Login
              </Link>
              <Link to="/signup" className="Poppins cursor-pointer ml-4">
                Signup
              </Link>
            </>
          )}
        </div>
      </div>

      <div className="bgDBT-img flex items-center justify-center xl:mt-5 mt-[8vh] pb-9">
        <div className="bg-white xl:w-[60%] lg:w-[80%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative mt-20">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />
          <section>
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                <div>
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                    Select Your Plan
                  </h2>
                </div>
                <div className="flex flex-wrap items-center gap-2">
                  <label
                    htmlFor="filter"
                    className="text-base font-semibold mb-2 sm:mb-0"
                  >
                    Select Your Plan Validity
                  </label>
                  <div className="relative w-full lg:w-1/2 md:w-full sm:w-auto">
                    <select
                      id="filter"
                      className="w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm appearance-none focus:outline-none focus:ring-2 focus:border-indigo-500"
                      onChange={handlePlanType}
                    >
                      <option value="lifetime">Lifetime</option>
                      <option value="yearly" disabled className="text-gray-400">
                        Yearly (Coming Soon)
                      </option>
                      <option value="month" disabled className="text-gray-400">
                        Monthly (Coming Soon)
                      </option>
                    </select>
                    <div className="absolute inset-y-0 right-2 flex items-center pr-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-5 lg:grid lg:grid-cols-3 xl:gap-5 lg:gap-2 lg:space-y-0">
                <div className="w-full">
                  <Card planValidity={planValidity} />
                </div>

                <div className="w-full">
                  <Web planValidity={planValidity} />
                </div>

                <div className="w-full">
                  <Premium planValidity={planValidity} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Plans;
