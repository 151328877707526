import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useUser } from "../../../authentication/AuthProvider";

const Header = () => {
  const { logout } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  
  const isLoggedInAdmin = localStorage.getItem("isLoggedIn")

  const handleLogout = async () => {
    if(window.confirm("Do you want to Logout ?")){
      await logout();
    }
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleActiveLink = (path) => {
    if (path === "/dashboardadmin") {
      setActiveLink("");
    } else {
      setActiveLink(path);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-blue-600 p-4 fixed w-full top-0 left-0 z-50 shadow-lg">
      <div className="mx-auto flex justify-between items-center">
        <Link to="/admindashboard" className="flex-shrink-0">
          <img src={logo} className="w-20 sm:w-32 md:w-40 lg:w-28" alt="Logo" />
        </Link>

        <div ref={menuRef} className="relative flex flex-col items-center">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white p-2 rounded-md focus:outline-none md:hidden"
          >
            <i className="fa fa-bars"></i>
          </button>

          <div
            className={`absolute top-full mr-6 md:mr-0 mt-2 py-4 bg-blue-600 rounded-md transition-all duration-200 ease-in-out ${
              menuOpen ? "opacity-100 visible" : "opacity-0 invisible"
            } md:opacity-100 md:visible md:relative md:flex md:flex-row md:space-x-4 md:top-0`}
          >
            {isLoggedInAdmin ? (
              <>
                <Link
                  to="/admindashboard/carddetail"
                  className={`block text-xs sm:text-sm font-semibold md:hidden m-2 py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    activeLink === "/admindashboard/carddetail"
                      ? "bg-orange-600 text-white"
                      : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                  }`}
                  onClick={() => handleActiveLink("/admindashboard/carddetail")}
                >
                  Add Plans
                </Link>

                <Link
                  to="/admindashboard/addresslist"
                  className={`block text-xs sm:text-sm font-semibold md:hidden m-2 py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    activeLink === "/admindashboard/addresslist"
                      ? "bg-orange-600 text-white"
                      : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                  }`}
                  onClick={() => handleActiveLink("/admindashboard/addresslist")}
                >
                  Add Address
                </Link>

                <Link
                  to="/admindashboard/userdetail"
                  className={`block text-xs sm:text-sm font-semibold md:hidden m-2 py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    activeLink === "/admindashboard/userdetail"
                      ? "bg-orange-600 text-white"
                      : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                  }`}
                  onClick={() => handleActiveLink("/admindashboard/userdetail")}
                >
                  User Detail
                </Link>

                <Link
                  to="/admindashboard/shipping"
                  className={`block text-xs sm:text-sm font-semibold md:hidden m-2 py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    activeLink === "/admindashboard/shipping"
                      ? "bg-orange-600 text-white"
                      : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                  }`}
                  onClick={() => handleActiveLink("/admindashboard/shipping")}
                >
                  Track Orders
                </Link>

                <Link
                  to="/admindashboard/shiporder"
                  className={`block text-xs sm:text-sm font-semibold md:hidden m-2 py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    activeLink === "/admindashboard/shiporder"
                      ? "bg-orange-600 text-white"
                      : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                  }`}
                  onClick={() => handleActiveLink("/admindashboard/shiporder")}
                >
                  Shipping Orders
                </Link>

                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <Link
                    to="/admindashboard"
                    className={`block text-xs sm:text-sm font-semibold py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                      activeLink === "/admindashboard"
                        ? "border-2 border-orange-500 bg-orange-600 text-white"
                        : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                    }`}
                    onClick={() => handleActiveLink("/admindashboard")}
                  >
                    Home
                  </Link>
                  <Link
                    onClick={handleLogout}
                    className="text-xs sm:text-sm bg-red-600 text-white py-2 px-3 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                  >
                    Logout
                  </Link>
                </div>
              </>
            ) : (
              <Link
                to="/admin/login"
                className="block text-sm bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
