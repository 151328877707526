import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import logo from "../../assets/images/astroLogo.png";

function InvoiceBillWeb({ invoiceWeb }) {
  // console.log(invoiceWeb);
  const styles = {
    page: {
      padding: 30,
      paddingTop: 30,
      fontFamily: "Helvetica",
    },
    header: {
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center",
      color: "#4F46E5",
    },
    section: {
      paddingTop: 10,
      marginBottom: 15,
    },
    table: {
      width: "100%",
      borderWidth: 1,
      borderColor: "#ddd",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableRow: {
      flexDirection: "row",
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      justifyContent: "space-between",
    },
    tableCellHeader: {
      flex: 1,
      paddingHorizontal: 8,
      textAlign: "center",
      fontWeight: "semi-bold",
      backgroundColor: "#f0f0f0",
      padding: 4,
    },
    tableCell: {
      flex: 1,
      paddingHorizontal: 8,
      textAlign: "center",
      fontSize: 12,
    },
    text: {
      fontSize: 11,
      marginBottom: 5,
    },
    orderText: {
      fontSize: 12,
      marginBottom: 20,
    },
    subHeader: {
      fontSize: 15,
      marginBottom: 5,
    },
    addressHeader: {
      marginBottom: 8,
      marginTop: 15,
    },
    totalSection: {
      marginTop: 20,
      textAlign: "right",
      fontSize: 14,
      fontWeight: "bold",
    },
    horizontalLine: {
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      marginVertical: 10,
    },
    logo: {
      position: "absolute",
      top: 0,
      left: 20,
      width: 100,
      height: 100,
    },
  };

  const planType = JSON.parse(localStorage.getItem("planType"));
  const planTypeWeb = planType.includes("Web");

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <Image style={styles.logo} src={logo} />

          <Text style={styles.header}>
            Invoice for Order {invoiceWeb.uuId || "N/A"}
          </Text>

          <View style={styles.section}>
            <Text style={styles.subHeader}>
              Invoice Number:{" "}
              <Text style={styles.text}>{invoiceWeb.uuId || "N/A"}</Text>
            </Text>
            <Text style={styles.subHeader}>
              <Text style={styles.subHeader}>
                Order Date:{" "}
                <Text style={styles.text}>
                  {invoiceWeb.orderDate
                    ? new Date(invoiceWeb.orderDate).toLocaleDateString("en-UK")
                    : "N/A"}
                </Text>
              </Text>
            </Text>
            <Text style={styles.subHeader}>
              Customer Name:{" "}
              <Text style={styles.text}>{`${invoiceWeb.firstName || ""} ${
                invoiceWeb.lastName || ""
              }`}</Text>
            </Text>
            <Text style={styles.subHeader}>
              Order Total:{" "}
              <Text style={styles.text}>
                {invoiceWeb.paymentAmount || 0} /-
              </Text>
            </Text>
          </View>
          <View style={styles.section}>
            <View style={styles.horizontalLine}></View>
            <View style={styles.addressSection}>
              <Text style={styles.subHeader}>Items Ordered:</Text>
              <Text style={styles.orderText}>
                {" "}
                Bharat Astrosage Web Services has been activated for your
                account.
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellHeader}>Item</Text>
              <Text style={styles.tableCellHeader}>Plan</Text>
              <Text style={styles.tableCellHeader}>Price</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>Web Services</Text>
              <Text style={styles.tableCell}>Astrosage Web Services</Text>
              <Text style={styles.tableCell}>
                {invoiceWeb.paymentAmount || 0} /-
              </Text>
            </View>
          </View>
          <View style={styles.totalSection}>
            <Text>Grand Total: {invoiceWeb.paymentAmount} /-</Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

export default InvoiceBillWeb;
