import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../apiContstants";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../authentication/AuthProvider";

const ShipDetail = () => {
  const [shipDetail, setShipDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { invalidToken } = useUser();
  const itemsPerPage = 10;
  const navigate = useNavigate();
  
  const isLoggedInAdmin = localStorage.getItem("isLoggedIn")

  useEffect(() => {
    if (!isLoggedInAdmin) {
      navigate("/login");
    }
  }, [isLoggedInAdmin, navigate]);

  const token = localStorage.getItem("Token");
  // console.log(token)

  useEffect(() => {
    const fetchShip = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/admin/allSuccessfullOrderDetails`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        });
        setShipDetail(response.data);
      } catch (error) {
        if(error.response.status === 401){
          invalidToken();
          alert("Please Login Again")
        } else {
          console.log("Error fetching shipping details:", error);
        }
      }
    };
    fetchShip();
  }, []);

  const maxPage = Math.ceil(shipDetail.length / itemsPerPage);

  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return shipDetail.slice(begin, end);
  };

  const next = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, Math.min(page, maxPage));
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full lg:w-9/12 md:w-9/12 sm:w-11/12 my-6">
      <section className="flex flex-col">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex justify-between items-center gap-3">
            <h3 className="text-lg font-semibold">Shipping Details</h3>
          </div>
          <table className="table-auto w-full mt-3">
            <thead className="bg-sky-300">
              <tr className="text-left border-b">
                <th className="p-2">S. No</th>
                <th className="p-2">Order No</th>
                <th className="p-2">Customer Name</th>
                <th className="p-2">ShipRocket Order Id</th>
                <th className="p-2">Order Date</th>
                <th className="p-2">Invoice</th>
                <th className="p-2">Label</th>
              </tr>
            </thead>
            <tbody>
              {currentData().map((order,index) => (
                <tr key={index} className="border-b">
                  <td className="py-2">{index + 1}</td>
                  <td className="py-2">{order.order_id}</td>
                  <td className="py-2">{`${order.shipping_customer_name} ${order.shipping_last_name}`}</td>
                  <td className="py-2">{order.shiprocketOrderId}</td>
                  <td className="py-2">{order.orderDate}</td>
                  <td className="py-2">
                    <button
                      className="text-sm bg-blue-500 text-white px-3 py-2 rounded"
                      onClick={() => window.open(order.invoice, "_blank")}
                    >
                      Download Invoice
                    </button>
                  </td>
                  <td className="py-2">
                    <button
                      className="text-sm bg-blue-500 text-white px-3 py-2 rounded"
                      onClick={() => window.open(order.label, "_blank")}
                    >
                      Download Label
                    </button>
                  </td>
                </tr>
              ))}
              {currentData().length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center font-semibold py-4">
                    No orders found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="flex justify-center items-center mt-4 gap-2">
            <button
              className="px-4 py-2 bg-gray-200 rounded"
              onClick={prev}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: maxPage }, (_, index) => (
              <button
                key={index + 1}
                className={`px-4 py-2 ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                } rounded`}
                onClick={() => jump(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="px-4 py-2 bg-gray-200 rounded"
              onClick={next}
              disabled={currentPage === maxPage}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShipDetail;
