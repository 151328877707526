import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Profile from "../homescreen/Profile";
import LOGO from "../../assets/images/logo.png";
import CARD from "../../assets/images/card.jpg";
import { useUser } from "../../authentication/AuthProvider";
import axios from "axios";
import { BASE_URL } from "../../apiContstants";

function OrderTrack() {
  const [order, setOrder] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { isLoggedIn, loginUser, invalidToken } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoggedIn, navigate]);

  const token = localStorage.getItem("Token");
  // console.log(token)

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const userProfileId =
          Array.isArray(loginUser) && loginUser.length > 0
            ? loginUser[0].userProfileId
            : loginUser.userProfileId || "";
        const response = await axios.get(
          `${BASE_URL}api/shiprocket/tracking1/status1/withAddress/basedOnUserId/${userProfileId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = response.data[0];
        setOrder(resData);

        // console.log(order);
      } catch (err) {
        // console.error("Error fetching order status:", err);
        if (err.response.status === 401) {
          invalidToken();
          alert("Your Session Expired Please Login Again");
        }
      }
    };

    fetchOrderStatus();
  }, []);

  const statusMapping = {
    1: "Processing",
    6: "Shipped",
    7: "Delivered",
  };

  const currentStatus = statusMapping[order.statusCode] || "Pending";

  const planType = localStorage.getItem("planType");
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

  return (
    <div
      className={
        !isMobile
          ? "bgDB-img bg-fixed min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]"
          : "bgDBT-img bg-fixed min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]"
      }
    >
      <div
        className="flex flex-row justify-between h-16 md:h-24 items-center px-4 md:px-2 lg:px-24 sticky top-0 z-20 bg-black"
        // style={{ background: "rgb(13 25 73)" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-12 md:h-16 lg:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row items-center justify-between md:gap-8 gap-4 md:pr-26 md:text-xl text-sm">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              {firstName !== null &&
                (planType.includes("Web") ||
                  planType.includes("Card and Web")) && (
                  <dl>
                    <Link
                      to="/dashboard"
                      className="Poppins text-white cursor-pointer"
                    >
                      Dashboard
                    </Link>
                  </dl>
                )}
              <Profile />
            </>
          ) : (
            <Link to="/login" className="Poppins cursor-pointer">
              Login
            </Link>
          )}
          {!isLoggedIn && (
            <Link to="/signup" className="Poppins cursor-pointer">
              Signup
            </Link>
          )}
        </div>
      </div>
      <div className="p-5 mt-12 w-full md:w-4/4 lg:w-3/5 xl:w-3/5 md:min-h-1/2">
        <div className="max-w-4xl p-6 bg-white bg-opacity-25 lg:bg-opacity-25 backdrop-blur-xl lg:backdrop-blur-xl shadow-lg rounded-lg border border-gray-200">
          <div className="relative z-10">
            <h1 className="text-lg sm:text-2xl font-bold mb-4 text-white">
              Order Status
            </h1>
            <div className="flex flex-col lg:flex-row justify-evenly">
              <div className="mt-4 text-white lg:w-1/2">
                <p className="text-lg">
                  <span className="font-semibold">Order ID :</span>{" "}
                  <span className="text-emerald-300">{order.shipmentId}</span>
                </p>
                <p className="text-lg">
                  <span className="font-semibold">Date & Time :</span>{" "}
                  <span className="text-emerald-300">{order.orderDate}</span>
                </p>
              </div>
              <div className="lg:w-1/3 w-full mt-6 lg:mt-0">
                <h4 className="text-white mb-5">
                  Order Item : <span className="text-green-500">AstroCard</span>
                </h4>
                <img
                  src={CARD}
                  alt="CARD"
                  className="object-cover md:w-full sm:w-1/2 rounded-md"
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="flex justify-between items-center">
                {Object.values(statusMapping).map((stage, index) => (
                  <div
                    key={index}
                    className={`relative flex flex-col items-center ${
                      index !== Object.keys(statusMapping).length - 1
                        ? "flex-1"
                        : ""
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full ${
                        Object.values(statusMapping).indexOf(currentStatus) >=
                        index
                          ? "bg-green-500"
                          : "bg-gray-400"
                      }`}
                    ></div>
                    <span
                      className={`mt-2 text-sm ${
                        Object.values(statusMapping).indexOf(currentStatus) >=
                        index
                          ? "text-green-500"
                          : "text-gray-400"
                      }`}
                    >
                      {stage}
                    </span>
                    {index !== Object.keys(statusMapping).length - 1 && (
                      <div
                        className={`absolute top-4 left-8 w-full border-t-2 ${
                          Object.values(statusMapping).indexOf(currentStatus) >=
                          index + 1
                            ? "border-green-500"
                            : "border-gray-400"
                        }`}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 w-full md:w-4/4 lg:w-3/5 xl:w-3/5">
        <h4 className="text-white text-center text-lg sm:text-xl md:text-2xl p-4 bg-white bg-opacity-25 backdrop-blur-xl shadow-lg rounded-lg border border-gray-200">
          Note :{" "}
          <span className="text-xs sm:text-lg">
            Tracking Details has been sent to your E-mail{" "}
            <span className="text-emerald-300 font-semibold">
              {order.shipping_email}.
            </span>
          </span>
        </h4>
      </div>
    </div>
  );
}

export default OrderTrack;
