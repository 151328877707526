import React, { useState } from "react";
import LOGO from "../../assets/images/logo.png";
import CARD from "../../assets/images/card.jpg";
import { useNavigate, Link, NavLink } from "react-router-dom";
import "../css/Homepage.css";
import CROSS from "../../assets/images/cross.png";
import CHECK from "../../assets/images/check.png";
import Profile from "./Profile";
import { useUser } from "../../authentication/AuthProvider";
import Footer from "../footer/Footer";

function Homescreen() {
  const [isCardOpen] = useState(false);
  const [isFullScreen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalClass, setModalClass] = useState(false);
  const { isLoggedIn, loginUser } = useUser();

  const navigate = useNavigate();

  const handleCardClick = () => {
    if (isLoggedIn) {
      setIsModalOpen(true);
    } else {
      navigate("/login");
    }
  };

  const handleCloseModal = () => {
    setModalClass("fade-out");
    setTimeout(() => {
      setIsModalOpen(false);
      setModalClass("");
    }, 2000);
  };

  const handlePurchase = () => {
    if (loginUser.status === "active") {
      navigate("/");
    } else {
      navigate("/plans");
    }
  };

  const userStatus =
    loginUser &&
    (Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0]?.status === "Success"
      : loginUser.status === "Success");

  const planType = localStorage.getItem("planType");
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

  return (
    <div>
      <div
        className={`bg-img min-h-screen w-[100%] bg-cover bg-center ${
          isFullScreen ? "fullscreen" : ""
        }`}
      >
        <div
          className="flex flex-row justify-between md:h-24 h-16 items-center px-4 lg:px-24 md:px-9 bg-black"
          // style={{ background: "#1b1919" }}
        >
          <div className="flex justify-center md:justify-start">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </div>
          <div className="flex flex-row items-center justify-between md:gap-8 gap-4 md:pr-26 md:text-xl text-sm">
            {isLoggedIn ? (
              <>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `Poppins text-white cursor-pointer p-2 rounded ${
                      isActive ? "bg-orange-500" : ""
                    }`
                  }
                >
                  Home
                </NavLink>
                {firstName !== null &&
                  (planType?.includes("Web") ||
                    planType?.includes("Card and Web")) && (
                    <dl>
                      <Link
                        to="/dashboard"
                        className="Poppins text-white cursor-pointer"
                      >
                        Dashboard
                      </Link>
                    </dl>
                  )}
                <Profile />
              </>
            ) : (
              <>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `Poppins cursor-pointer p-2 rounded ${
                      isActive ? "bg-orange-500 text-white" : ""
                    }`
                  }
                >
                  Home
                </NavLink>
                <Link to="/plans" className="Poppins text-white cursor-pointer">
                  Plans
                </Link>
                <Link to="/login" className="Poppins text-white cursor-pointer">
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="Poppins text-white cursor-pointer"
                >
                  Signup
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="md:flex xl:mx-40 md:mx-2 mx-4 xl:gap-6 lg:gap-2 md:gap-6 items-center justify-center xl:mt-36 md:mt-20 mt-9 xl:pb-[48.2px] lg:pb-[50px] pb-[40px]">
          <div className="lg:w-[60%] md:w-3/4 xl:ml-40 lg:ml-9 md:ml-8">
            <p className="Anton text-white lg:text-7xl md:text-4xl text-4xl lg:text-left md:text-left text-center font-normal tracking-wider">
              BHARAT ASTROCARD
            </p>
            <div className="leading-10">
              <p
                style={{ color: "#ffe6ac" }}
                className="Poppins xl:mt-5 mt-2 lg:text-left md:text-left text-center xl:text-[18px] lg:text-sm md:text-[16px] text-[17px] lg:mx-0 md:mx-0 xl:leading-1 lg:leading-8 md:leading-8 leading-5"
              >
                Unveil the mysteries of the cosmos with our daily astrology
                card. Your personalized guide to navigating the stars awaits!
                Dive in and discover what the universe has in store for you
                today.
              </p>
            </div>
            <div className="lg:mt-8 mt-12 flex justify-center md:justify-start gap-8">
              <button
                onClick={handleCardClick}
                className="text-white hover:text-[#243f69] border-2 border-white relative overflow-hidden xl:px-9 md:px-7 px-6 xl:py-3 md:py-1 py-2 Poppins"
              >
                <span className="absolute left-0 top-0 w-0 h-full transition-all"></span>
                <span className="relative z-10">
                  {userStatus ||
                  (planType &&
                    (planType.includes("Web") ||
                      planType.includes("Card") ||
                      planType.includes("Card and Web")))
                    ? "Upgrade Plan"
                    : "Buy Now"}
                </span>
              </button>
              <button
                className="text-white hover:text-[#243f69] border-2 border-white relative overflow-hidden xl:px-9 md:px-18 px-6 xl:py-3 md:py-0 py-2 Poppins"
                onClick={() => navigate("/howtouse")}
              >
                <span className="absolute left-0 top-0 w-0 h-full transition-all"></span>
                <span className="relative z-10">How to use?</span>
              </button>
            </div>
          </div>
          <div
            className={`card rounded-2xl xl:mt-0 lg:mt-[-10vh] md:mt-[-15vh] mt-9 xl:w-11/12 lg:mr-[45px] md:mr-[40px] mr-0 lg:w-[60%] md:w-[70%] w-[65%] cursor-pointer ${
              isCardOpen ? "open" : ""
            }`}
            onClick={handleCardClick}
          >
            <div className="rounded-2xl flex xl:items-center xl:justify-center">
              <img
                src={CARD}
                alt="card"
                className="rounded-2xl lg:w-80 xl:w-[75%] md:w-[83%] w-72"
                style={{ maxWidth: "130%" }}
              />
              <div className="cover-media" src={CARD}></div>
              <div className="cover-link"></div>
            </div>
            <div className="card-3d-right-side" src={CARD}></div>
            <div className="card-3d-bottom-side" src={CARD}></div>
          </div>
        </div>

        {isLoggedIn && isModalOpen && (
          <div
            className={`fixed top-0 left-0 w-full h-full bg-img bg-opacity-95 z-50 ${
              isModalOpen ? "fade-in" : ""
            } modal ${modalClass ? "fade-out" : ""}`}
          >
            <div className="absolute xl:top-[10%] lg:top-[9%] md:top-[8%] top-[10%] xl:left-[52%] md:left-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 lg:w-[80%] md:w-[90%] w-[95%] rounded-[15px] shadow-xl p-[12px]">
              <div className="bg-color border border-gray-600 rounded-[20px] fixed top-0 left-0 z-50">
                <div className="text-white flex justify-end pt-4 md:pr-8 pr-4">
                  <img
                    src={CROSS}
                    className="md:h-5 h-3 cursor-pointer"
                    onClick={handleCloseModal}
                    alt="close"
                  />
                </div>
                <div className="flex items-center justify-center lg:flex-row flex-col xl:gap-[100px] lg:gap-[30px] py-[2vh]">
                  <div className="xl:w-[10%] w-0"></div>
                  <div className="xl:w-[90%] lg:w-[100%] md:w-[70%] w-[100%] md:ml-6 ml-2 flex justify-center">
                    <img
                      src={CARD}
                      className="xl:w-[100%] lg:w-[100%] xl:h-[350px] lg:h-[200px] md:h-full w-[96%] border border-gray-700 rounded-[20px]"
                      alt="card-detail"
                    />
                  </div>
                  <div className="xl:w-[40%] lg:w-[100%] w-[100%] xl:mt-0 mt-5 flex flex-col items-center lg:items-start">
                    <div className="ml-6">
                      <p
                        style={{ fontWeight: 400 }}
                        className="text-white xl:text-5xl lg:text-3xl md:text-3xl text-[26px] Anton tracking-wide xl:leading-tight lg:leading-sung md:leading-normal text-center lg:text-left"
                      >
                        GET YOUR DAILY{" "}
                        <span className="text-[#ffe6ac]">HOROSCOPES</span> WITH
                        JUST A TAP
                      </p>
                    </div>
                    <div className="mt-5 lg:ml-4 md:ml-8 ml-6">
                      {[
                        "Premium Card",
                        "NFC",
                        "Great Design",
                        "Lifetime validity",
                      ].map((feature) => (
                        <div className="flex items-center gap-3" key={feature}>
                          <img
                            src={CHECK}
                            className="xl:w-7 lg:w-5 md:w-6 w-5"
                            alt="check"
                          />
                          <p className="text-[#ffe6ac] arial xl:text-[21px] lg:text-[18px] md:text-[22px] text-[18px]">
                            {feature}
                          </p>
                        </div>
                      ))}
                      <div className="my-6 xl:ml-8 ml-0">
                        <button
                          onClick={handlePurchase}
                          className="relative bg-[#ffe6ac] xl:px-14 px-8 xl:py-3 py-2 font-semibold text-[14px] arial overflow-hidden transition-all duration-300 hover:bg-[#243f69] hover:text-white"
                        >
                          <span className="absolute inset-0 bg-blue-500 transition-transform duration-300 transform translate-x-full"></span>
                          <span className="relative z-10">Purchase Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-[15%] w-0"></div>
                </div>
              </div>
            </div>
            <div className="absolute xl:top-[10%] lg:top-[9%] md:top-[8%] top-[10%] xl:left-[52%] md:left-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 lg:w-[80%] md:w-[90%] w-[95%] rounded-[15px] shadow-xl p-[12px]">
              <div className="bg-color border border-gray-600 rounded-[20px] fixed top-0 left-0 z-50">
                <div className="text-white flex justify-end pt-4 md:pr-8 pr-4">
                  <img
                    src={CROSS}
                    className="md:h-5 h-3 cursor-pointer"
                    onClick={handleCloseModal}
                    alt="close"
                  />
                </div>
                <div className="flex items-center justify-center lg:flex-row flex-col xl:gap-[100px] lg:gap-[30px] py-[2vh]">
                  <div className="xl:w-[10%] w-0"></div>
                  <div className="xl:w-[90%] lg:w-[100%] md:w-[70%] w-[100%] md:ml-6 ml-2 flex justify-center">
                    <img
                      src={CARD}
                      className="xl:w-[100%] lg:w-[100%] xl:h-[350px] lg:h-[200px] md:h-full w-[96%] border border-gray-700 rounded-[20px]"
                      alt="card-detail"
                    />
                  </div>
                  <div className="xl:w-[40%] lg:w-[100%] w-[100%] xl:mt-0 mt-5 flex flex-col items-center lg:items-start">
                    <div className="ml-6">
                      <p
                        style={{ fontWeight: 400 }}
                        className="text-white xl:text-5xl lg:text-3xl md:text-3xl text-[26px] Anton tracking-wide xl:leading-tight lg:leading-sung md:leading-normal text-center lg:text-left"
                      >
                        GET YOUR DAILY{" "}
                        <span className="text-[#ffe6ac]">HOROSCOPES</span> WITH
                        JUST A TAP
                      </p>
                    </div>
                    <div className="mt-5 lg:ml-4 md:ml-8 ml-6">
                      {[
                        "Premium Card",
                        "NFC",
                        "Great Design",
                        "Lifetime validity",
                      ].map((feature) => (
                        <div className="flex items-center gap-3" key={feature}>
                          <img
                            src={CHECK}
                            className="xl:w-7 lg:w-5 md:w-6 w-5"
                            alt="check"
                          />
                          <p className="text-[#ffe6ac] arial xl:text-[21px] lg:text-[18px] md:text-[22px] text-[18px]">
                            {feature}
                          </p>
                        </div>
                      ))}
                      <div className="my-6 xl:ml-8 ml-0">
                        <button
                          onClick={handlePurchase}
                          className="relative bg-[#ffe6ac] xl:px-14 px-8 xl:py-3 py-2 font-semibold text-[14px] arial overflow-hidden transition-all duration-300 hover:bg-[#243f69] hover:text-white"
                        >
                          <span className="absolute inset-0 bg-blue-500 transition-transform duration-300 transform translate-x-full"></span>
                          <span className="relative z-10">Purchase Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-[15%] w-0"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Homescreen;
