import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiContstants";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../authentication/AuthProvider";

function TrackCard() {
  const [ userTrack, setUserTrack ] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All');
  const [ currentPage, setCurrentPage ] = useState(1);
  const { invalidToken } = useUser();
  const navigate = useNavigate();
  
  const itemsPerPage = 10
  
  const isLoggedInAdmin = localStorage.getItem("isLoggedIn")

  useEffect(() => {
    if (!isLoggedInAdmin) {
      navigate("/login")
    }
  }, [isLoggedInAdmin, navigate])

  const token = localStorage.getItem("Token");
  // console.log(token)
  
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/admin/getShipmentStatusForAllUsers`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        });
        // console.log(response.data)
        setUserTrack(response.data);
      } catch (error) {
        if(error.response.status === 401){
          invalidToken();
          alert("Your Session Expired Please Login Again")
        } else {
          console.log("Error in response", error);
        }
      }
    };
    fetchPlans();
  }, []);

  const maxPage = Math.ceil(userTrack?.length / itemsPerPage);

  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return userTrack?.slice(begin, end);
  }

  const next = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, maxPage))
  }

  const prev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const jump = (page) => {
    const pageNumber = Math.max(1, Math.min(page, maxPage))
    setCurrentPage(pageNumber)
  }

  const processing = userTrack ? userTrack.filter((user) => user.statusCode === 1) : [];
  const ship = userTrack ? userTrack.filter((user) => user.statusCode === 6) : [];
  const delivered = userTrack ? userTrack.filter((user) => user.statusCode === 7) : [];

  return (
    <div className="w-full lg:w-9/12 md:w-9/12 sm:w-11/12 my-6">
      <section className="mb-4">
        <h2 className="text-lg text-white font-bold mb-2">Overview</h2>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-3">
            <div className="p-4 rounded-lg shadow-sm text-center bg-sky-300">
              <h3 className="text-lg font-semibold">{processing.length}</h3>
              <p className="text-sm font-medium">Processing</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-3">
            <div className="p-4 rounded-lg shadow-sm text-center bg-sky-300">
              <h3 className="text-lg font-semibold">{ship.length}</h3>
              <p className="text-sm font-medium">Shipped</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-3">
            <div className="p-4 rounded-lg shadow-sm text-center bg-sky-300">
              <h3 className="text-lg font-semibold">{delivered.length}</h3>
              <p className="text-sm font-medium">Delivered</p>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex justify-between items-center gap-3 mt-3">
            <section>
              <h3 className="text-lg font-semibold">Last Orders</h3>
            </section>
            <section className="pr-8">
            <label htmlFor="statusFilter" className="font-medium pr-2">Filter by Status:</label>
            <select
              id="statusFilter"
              className="form-select border border-gray-500 p-1 rounded-md"
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Processing">Processing</option>
              <option value="Shipped">Shipped</option>
              <option value="Delivered">Delivered</option>
            </select>
            </section>
          </div>

          <table className="table-auto w-full mt-3">
            <thead className="bg-sky-300">
              <tr className="text-left border-b">
                <th className="p-2">Order ID</th>
                <th className="p-2">Order Date & Time</th>
                <th className="p-2">Product</th>
                <th className="p-2">Customer Name</th>
                <th className="p-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentData()?.map((order) => (
                <tr key={order.order_id} className="border-b">
                  <td className="py-2">{order.order_id}</td>
                  <td className="py-2">{order.orderDate}</td>
                  <td className="py-2">AstroCard</td>
                  <td className="py-2">{`${order.shipping_customer_name} ${order.shipping_last_name}`}</td>
                  <td className="py-2">
                    <span className={`inline-block px-2 py-1 text-white rounded-full text-xs ${
                      order.statusCode === 1 ? 'Proccessing' :
                      order.statusCode === 6 ? 'Shipped' :
                      order.statusCode === 7 ? 'Delevered' :
                      'bg-green-500'
                    }`}>
                      {order.status}
                    </span>
                  </td>
                </tr>
              ))}
              {userTrack?.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center py-4">No orders found.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="flex justify-center items-center mt-4 gap-2">
            <button
              className="px-4 py-2 bg-gray-200 rounded"
              onClick={prev}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            {
              Array.from({ length: maxPage }, (_, index) => (
                <button
                  key={index + 1}
                  className={`px-4 py-2 ${
                    currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                  } rounded`}
                  onClick={() => jump(index + 1)}
                >
                  {index + 1}
                </button>
              ))
            }
            <button
              className="px-4 py-2 bg-gray-200 rounded"
              onClick={next}
              disabled={currentPage === maxPage}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TrackCard;
