import React, { useState } from "react";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BASE_URL } from "../apiContstants";
import axios from "axios";
import { toast } from "react-toastify";

function Signup() {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    error: "",
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevVisible) => ({
      ...prevVisible,
      [field]: !prevVisible[field],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      error: "",
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (
      !formData.userName ||
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setFormData({
        ...formData,
        error: "All fields are mandatory",
      });
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      setFormData({
        ...formData,
        error:
          "Password must be at least 8 characters long, include at least 1 uppercase, 1 lowercase, 1 number, and 1 special character",
      });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setFormData({
        ...formData,
        error: "Passwords not match",
      });
      return;
    }

    const signupData = {
      userName: formData.userName,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };

    try {
      // Create FormData object and append the signupData
      const signData = new FormData();
      for (const [key, value] of Object.entries(signupData)) {
        signData.append(key, value);
      }

      const response = await axios.post(`${BASE_URL}api/auth/save`, signData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // console.log("Response Data:", response.data);

      setFormData({
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        error: "",
      });
      toast.success("SignUp Successfully");
      navigate("/login");
    } catch (err) {
      setFormData({
        ...formData,
        error:
          err.response?.data?.message || "Network error. Please try again.",
      });
    }
  };

  return (
    <div className="bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]">
      <div
        className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-9 bg-black"
        // style={{ background: "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row items-center md:gap-8 gap-4 pr-26 md:text-xl text-sm">
          <Link to="/" className="Poppins text-white cursor-pointer">
            Home
          </Link>
          <Link to="/plans" className="Poppins text-white cursor-pointer">
            Plans
          </Link>
          <Link to="/login" className="Poppins text-white cursor-pointer">
            Login
          </Link>
          <NavLink
            to="/signup"
            className={({ isActive }) =>
              `Poppins cursor-pointer p-2 rounded ${
                isActive ? "bg-orange-500 text-white" : ""
              }`
            }
          >
            Signup
          </NavLink>
        </div>
      </div>

      <div className="flex items-center justify-center xl:mt-20 sm:mt-20 mt-[8vh]">
        <div className="bg-white xl:w-1/3 lg:w-[40%] md:w-2/4 md:mx-0 mx-4 py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your icon"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />

          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Sign up and Get access
          </h1>

          <form className="mt-4 px-12" onSubmit={handleSignup}>
            <input
              type="text"
              name="userName"
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
              placeholder="UserName"
              value={formData.userName}
              onChange={handleInputChange}
            />

            <input
              type="email"
              name="email"
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleInputChange}
            />
            <div className="relative">
              <input
                type={showPassword.password ? "text" : "password"}
                name="password"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-500 hover:text-indigo-500 focus:outline-none"
                onClick={() => togglePasswordVisibility("password")}
                aria-label={
                  showPassword.password ? "Hide password" : "Show password"
                }
              >
                <i
                  className={`fas ${
                    showPassword.password ? "fa-eye-slash" : "fa-eye"
                  }`}
                />
              </button>
            </div>

            <div className="relative">
              <input
                type={showPassword.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-500 hover:text-indigo-500 focus:outline-none"
                onClick={() => togglePasswordVisibility("confirmPassword")}
                aria-label={
                  showPassword.confirmPassword
                    ? "Hide password"
                    : "Show password"
                }
              >
                <i
                  className={`fas ${
                    showPassword.confirmPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                />
              </button>
            </div>
            {formData.error && (
              <p className="text-red-500 text-center mt-4">{formData.error}</p>
            )}

            <div className="flex justify-center mt-4 my-4">
              <button
                type="submit"
                style={{ background: "#000134", fontSize: "15px" }}
                className="w-full rounded-md py-2 text-white font-medium Poppins"
              >
                Signup
              </button>
            </div>
            <div className="text-center flex justify-center gap-1">
              Already Have An Account ?
              <Link to="/login" className="text-blue-500 underline">
                {" "}
                Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
